import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {axios} from "@/lib/axios";
import {useProjectStore} from "@/stores/project";
import {useLoadingStore} from "@/stores/loading";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import * as Attributes from "@/components/Form/Attributes";
import {SelectBox} from "@/components/Form/SelectBox";
import {useForm, useFormContext, useWatch} from "react-hook-form";
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {useBlockerStore} from "@/stores/blocker";
import {useMessageModalStore} from "@/stores/message";
import {useMapDrawing} from "@/stores/mapDrawing";
import {OpenMapButton} from "@/components/Attributes/OpenMapButton";
import {ViewMapButton} from "@/components/ViewMapButton";
import {
  EditUpdatedMunicipalitiesContainer as RenderUpdatedMunicipalitiesContainer
} from "@/components/Attributes/BasicMap/EditUpdatedMunicipalitiesContainer";
import {
  EditCertificationContainer as CertificationContainer
} from "@/components/Attributes/BasicMap/EditCertificationContainer";
import {EditBasicMapStatus} from "@/components/Attributes/BasicMap/BasicMapStatus";
import ConfirmModal from "@/components/ConfirmModal";
import {basicMapStore} from "@/stores/basicMap";
import {
  PostDataBasicMapCity,
  PostDataSubmissionStatus,
} from "@/components/Attributes/BasicMap/types";
import {useBasicMap} from "@/utils/useBasicMap";
import {SecondaryMeshAutoFill} from "@/components/Attributes/BasicMap/SecondaryMeshAutoFill";
import {useMeshAttribute} from "@/components/Attributes/MeshAttribute/useMeshAttribute";
import {DownloadGeoJsonButton} from "@/components/Attributes/DownloadGeoJsonButton";

interface AttributeInputProps {
  isType: Config.Data;
  tab: any;
  tabIndex: number;
}

export interface AttributeInputMethods {
  submit: () => Promise<any>;
}

export const AttributeInput = forwardRef<
  AttributeInputMethods,
  AttributeInputProps
>((props, ref) => {
  useImperativeHandle(ref, () => ({
    submit,
  }));
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: {errors},
  } = useFormContext();

  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const [selectedProject, selectedProjectCategory] = useProjectStore(
    (state) => [state.project, state.projectCategory]
  );
  const {
    postBasicMapCertification,
    basicMapCitiesPostData,
    setBasicMapCityErrors,
    basicMapBlockId,
    setBasicMapCertificationErrors
  } = useBasicMap();
  const {certification} = basicMapStore();
  const [isBlocker, setBlocker] = useBlockerStore((state) => [
    state.isBlocker,
    state.setBlocker,
  ]);
  const [navigatePath, setNavigatePath] = useState("");

  const [areaName, setAreaName] = useState<string>("エリア");
  const [blockName, setBlockName] = useState<string>("ブロック");
  const [areas, setAreas] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [tabStatuses, setTabStatuses] = useState<any[]>([]);
  const [data, setData] = useState<any>(undefined); // タブごと
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [dataMap, setDataMap] = useState<Map<string, any> | undefined>(
    undefined
  );

  const isBasicMap =
    selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isHiddenStatus = isBasicMap;
  const isBasicMapStatus = isBasicMap && props.tabIndex === 0;
  const isBasicMapCertification = isBasicMap && props.tabIndex === 1;
  const isHiddenRemarks = isBasicMap && props.tabIndex === 1;

  const selectedTabStatus = useWatch({
    control,
    name: "data_tab_statuses",
  });

  const meshRef = useRef<AttributeInputMethods>(null);
  const roadRef = useRef<AttributeInputMethods>(null);

  useBeforeUnload(
    useCallback(() => {
      // axiosだとKeepAliveがない？（fetchのみ？）
      if (params.dataId && props.isType === Config.Data.AttributeEdit)
        navigator.sendBeacon(`${Config.API_URL}/api/v1/datas/unlock/${params.dataId}`);
    }, [])
  );

  useEffect(() => {
    getInputs();
    if (params.dataId && props.isType === Config.Data.AttributeEdit) {
      const start = performance.now();
      const timer = setTimeout(() => {
        console.log("timer");
        console.log(performance.now() - start);
        setBlocker(false);
        setNavigatePath(
          Common.attributesDetailUrl(params.projectCategoryId, params.dataId)
        );
      }, 2 * 60 * 60 * 1000);

      return () => {
        navigator.sendBeacon(`${Config.API_URL}/api/v1/datas/unlock/${params.dataId}`);
        clearTimeout(timer);
      };
    }
  }, [location.pathname]);

  useEffect(() => {
    // 入力項目設定後でないとうまく反映されない
    if (
      attributes.length > 0 &&
      (tabStatuses.length > 0 || isBasicMap) &&
      data
    ) {
      if (params.dataId) {
        setupInput(data, attributes);
      } else {
        setupDefaultInput(attributes);
      }
    }
    return () => {
    };
  }, [attributes, tabStatuses, data]);

  useEffect(() => {
    if (!isBlocker && navigatePath !== "") {
      navigate(navigatePath);
    }
    return () => {
    };
  }, [isBlocker, navigatePath]);

  function getInputs() {
    setLoading(true);
    axios
      .get("/api/v1/attributes/inputs", {
        params: {
          project_id: selectedProject?.id,
          project_category_id: selectedProjectCategory?.id,
          tab_id: props.tab.id,
          tab_index: props.tabIndex,
          // tab_id: tab.id,
          // tab_index: tab_index,
          data_id: params.dataId,
          edit_flg: 1,
        },
      })
      .then((result) => {
        setAreaName(result.data.project.area_display_name);
        setBlockName(result.data.project.block_display_name);
        setAreas(result.data.areas);
        const filteredBlocks = Common.filteredBlocks(
          result.data.blocks,
          selectedProjectCategory
        );
        setBlocks(filteredBlocks);
        setAttributes(result.data.attributes);
        setTabStatuses(result.data.tabStatuses);
        setData(result.data.data);
      })
      .catch((error) => {
        if (error.response.status === 423)
          setNavigatePath(
            Common.attributesDetailUrl(params.projectCategoryId, params.dataId)
          );
        else setMessage(Config.MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setupDefaultInput = (attributes: any[]) => {
    const data = {} as any;
    if (selectedProjectCategory?.category_code === Config.CATEGORY_CODE_FAQ) {
      data["area_id"] = areas[0].id.toString();
      data["block_id"] = blocks[0].id.toString();
    }
    data["attributes"] = {} as any;
    for (const attribute of attributes) {
      if (attribute.attribute_setting_values.length === 0) continue;
      const defaultCode = attribute.attribute_setting_values.find(
        (setting_value: any) =>
          setting_value.attribute_setting_code ===
          Config.ATTRIBUTE_SETTING_DEFAULT_CODE
      );
      const defaultValue = attribute.attribute_setting_values.find(
        (setting_value: any) =>
          setting_value.attribute_setting_code ===
          Config.ATTRIBUTE_SETTING_DEFAULT_VALUE
      );
      if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
        if (defaultCode && defaultValue) {
          const option = attribute.attribute_options.find(
            (o: any) =>
              o.code === defaultCode.value && o.option === defaultValue.value
          );
          if (option)
            data["attributes"][attribute.id] =
              Common.createAttributeOptionValue(
                option.id,
                option.code,
                option.option
              );
        }
      } else {
        if (defaultValue) {
          data["attributes"][attribute.id] = defaultValue.value;
        }
      }
    }
    setDataMap(dataMap);
    reset(data);
  };

  const setupInput = (getData: any, attributes: any[]) => {
    const dataMap = Common.convertToKeyMap(getData, "attribute_id");
    const attributeMap = Common.attributeMap(attributes, "id");
    const data = {} as any;
    if (props.isType !== Config.Data.AttributeCopy)
      data["data_tab_statuses"] =
        getData?.data_tab_statuses?.[0]?.tab_statuses_id.toString();
    if (
      props.tabIndex === 0 &&
      !(isBasicMap && props.isType == Config.Data.AttributeCopy)
    ) {
      data["area_id"] = getData.area_id.toString();
      data["block_id"] = getData.block_id.toString();
    }
    data["remarks"] = getData?.remarks;
    data["attributes"] = {} as any;
    for (const attribute of attributes) {
      const dataAttributeValue = dataMap.get(attribute.id);
      if (dataAttributeValue === undefined) continue;
      if (attribute.is_copy === 0 && props.isType === Config.Data.AttributeCopy)
        continue;

      if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
        if (Array.isArray(dataAttributeValue)) {
          data["attributes"][attribute.id] = dataAttributeValue.map(
            (value: any) => {
              const option = Common.getAttributeOption(
                value,
                dataMap,
                attributeMap,
                attribute.id,
                getData.area_id,
                getData.block_id
              );
              if (option)
                return Common.createAttributeOptionValue(
                  option.id,
                  value.attribute_code,
                  value.attribute_value
                );
            }
          );
        } else {
          const option = Common.getAttributeOption(
            dataAttributeValue,
            dataMap,
            attributeMap,
            attribute.id,
            getData.area_id,
            getData.block_id
          );
          if (option)
            data["attributes"][attribute.id] =
              Common.createAttributeOptionValue(
                option.id,
                dataAttributeValue.attribute_code,
                dataAttributeValue.attribute_value
              );
        }
      } else if (
        attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_TEXT ||
        attribute.attribute_kind_code ===
        Config.ATTRIBUTE_KINDS_CODE_MAP_DRAWING
      ) {
        data["attributes"][attribute.id] = dataAttributeValue.text;
      } else {
        data["attributes"][attribute.id] = dataAttributeValue.attribute_value;
      }
    }
    // console.log("setupInput")
    // console.log(data)
    setDataMap(dataMap);
    reset(data);
  };

  async function post(inputData: any) {
    if (isBasicMapCertification) {
      return await postBasicMapCertification(
        inputData,
        params.dataId,
        setLoading,
        certification?.id
      ).catch((error: any) => {
        if (!error?.response) {
          setMessage(Config.MESSAGE_NO_E37);
        } else {
          const errors = error?.response?.data?.message;
          setErrorMessage("入力エラーがあります");
          setBasicMapCertificationErrors(setError, errors);
        }
        return false;
      });
    }

    const [data_attribute_values, data_attribute_text_values] =
      Common.inputAttributeToPostParam(attributes, inputData, dataMap);

    let postData = {
      id: params.dataId,
      project_id: selectedProject?.id,
      project_category_id: selectedProjectCategory?.id,
      area_id: inputData.area_id,
      block_id: inputData.block_id,
      remarks: inputData.remarks,
      data_attribute_values: data_attribute_values,
      data_attribute_text_values: data_attribute_text_values,
      data_tab_statuses: [
        {
          id: data?.data_tab_statuses?.[0]?.id ?? undefined,
          project_category_id: selectedProjectCategory?.id,
          tab_id: props.tab.id,
          tab_statuses_id: inputData.data_tab_statuses,
        },
      ],
      is_copy: props.isType === Config.Data.AttributeCopy,
    };

    if (isBasicMap) {
      const basic_map_cities = basicMapCitiesPostData(
        inputData.basic_map_cities,
        props.isType
      );
      const submission_status = {
        id: certification?.id,
        status: inputData.submission_status,
      };
      postData = {
        ...postData,
        block_id: basicMapBlockId(blocks, postData.area_id),
        basic_map_cities,
        submission_status,
      } as typeof postData & {
        basic_map_cities: PostDataBasicMapCity[];
        submission_status: PostDataSubmissionStatus;
      };
    }

    setLoading(true);
    return axios
      .post("/api/v1/datas/post", postData)
      .then(async (result) => {
        if (meshRef.current) {
          await meshRef.current.submit();
        }

        if (roadRef.current) {
          await roadRef.current.submit();
        }

        return result.data.result;
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          const errors = error?.response?.data?.errors;
          setErrorMessage("入力エラーがあります");
          if (errors.area_id)
            setError("area_id", {
              type: "sever",
              message: Common.getNestedValue(errors.area_id),
            });
          if (errors.block_id)
            setError("block_id", {
              type: "sever",
              message: Common.getNestedValue(errors.block_id),
            });
          if (errors.data_tab_statuses)
            setError("data_tab_statuses", {
              type: "sever",
              message: Common.getNestedValue(errors.data_tab_statuses),
            });
          if (errors.remarks)
            setError("remarks", {
              type: "sever",
              message: Common.getNestedValue(errors.remarks),
            });
          for (const key in errors.attributes) {
            setError(`attributes.${key}`, {
              type: "sever",
              message: Common.getNestedValue(errors.attributes[key]),
            });
          }
          if (errors.data_basic_map_cities) {
            setBasicMapCityErrors(setError, errors.data_basic_map_cities);
          }
        } else if (error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setMessage(Config.MESSAGE_NO_E37);
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function submit() {
    clearErrors();
    const data = getValues();
    return await post(data);
  }

  return (
    <div>
      <form>
        {errorMessage.length > 0 && (
          <div className="error-box">{errorMessage}</div>
        )}
        <div className="precaution">※は必須です</div>

        <div className="info-tbl_wrap mb-40">

          {Common.isServiceStartInfo(selectedProjectCategory) && (
            <>
              <span className="manage-top-circle">●</span><span
              className="manage-top">は供用予定管理リストの項目</span>、<span className="collect-top-circle">●</span><span
              className="collect-top">は資料収集リストの項目</span>です。
            </>
          )}
          <div
            className="info-tbl"
            style={{display: isHiddenStatus ? "none" : ""}}
          >
            <table className="uk-width-1-2">
              <tbody>
              <tr>
                <td className="label">
                  ステータス<span className="error">※</span>
                </td>
                <td className="content">
                  <select
                    className={`uk-width-1-1 ${
                      selectedTabStatus ? "" : "placeholder"
                    } ${errors.data_tab_statuses ? "error-form" : ""}`}
                    defaultValue={""}
                    {...register(`data_tab_statuses`, {
                      required: "必須項目です",
                    })}
                  >
                    <option value="" disabled>
                      選択してください
                    </option>
                    {tabStatuses.map((o: any) => {
                      return (
                        <option
                          key={`data_tab_statuses_${o.id}`}
                          value={o.id}
                        >
                          {o.code + ": " + o.status}
                        </option>
                      );
                    })}
                  </select>
                  {errors.data_tab_statuses && (
                    <div className="error">{`${errors.data_tab_statuses.message}`}</div>
                  )}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          {isBasicMapStatus && <EditBasicMapStatus isType={props.isType}/>}

          <div className="info-tbl">
            <table className="uk-width-1-1">
              <tbody>
              {props.tabIndex === 0 && attributes[0] ? (
                <InputDefaultRows
                  isType={props.isType}
                  selectedProjectCategory={selectedProjectCategory}
                  attributes={attributes}
                  dataMap={dataMap}
                  areas={areas}
                  blocks={blocks}
                  areaName={areaName}
                  blockName={blockName}
                  meshRef={meshRef}
                  roadRef={roadRef}
                />
              ) : (
                <InputRows
                  isType={props.isType}
                  attributes={attributes}
                  meshRef={meshRef}
                />
              )}
              </tbody>
            </table>
          </div>

          {isHiddenRemarks && <CertificationContainer/>}

          <div
            className="info-tbl"
            style={{display: isHiddenRemarks ? "none" : ""}}
          >
            <table className="uk-width-1-1">
              <tbody>
              <tr>
                <td className="label">備考（内部用）</td>
                <td colSpan={3}>
                    <textarea
                      className={`uk-width-1-1 h-87 ${
                        errors["remarks"] ? "error-form" : ""
                      }`}
                      {...register("remarks")}
                    />
                  {errors["remarks"] && (
                    <div className="error">{`${errors["remarks"]?.message}`}</div>
                  )}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        {Config.isAttributeNew(props.isType) && <SecondaryMeshAutoFill attributes={attributes}/>}
      </form>
      {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}
    </div>
  );
});

const RenderRow = ({
                     isType,
                     attr,
                     dataAttributeValue,
                     selectedBlock,
                     meshRef,
                     roadRef,
                     baseDataNumberAttribute,
                   }: {
  isType: Config.Data;
  attr: any;
  dataAttributeValue?: any;
  selectedBlock?: any;
  meshRef?: any;
  roadRef?: any;
  baseDataNumberAttribute?: any;
}) => {
  const {getValues, setValue, control} = useFormContext();
  const {p_id} = useMapDrawing();

  const attrValue = getValues(`attributes.${attr.id}`) || "";
  const required = attr.attribute_setting_values.find(
    (setting: any) =>
      setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_REQUIRED
  );
  const isMapButton = Common.isMapButtonActive(attr);
  const isRequestedLocation = attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.requestedLocation;
  const isMapDrawing = Common.isMapDrawing(attr);
  const isPId = Common.isPId(attr);
  const isViaMesh = attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.viaMesh;
  const isRoad = attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.road;
  const isUpdatedMunicipalities = useBasicMap().isUpdatedMunicipalities(attr);
  const isOtherItem = Common.OtherAttributes.isOtherItem(attr);

  const params = useParams();
  const [meshList, setMeshList] = useState<MeshInfo[]>([]);
  const [deleteMeshList, setDeleteMeshList] = useState<MeshInfo[]>([]);
  const [dicRoadAttributes, setDicRoadAttributes] = useState<any[]>([]);
  const [deleteDicRoadAttributes, setDeleteDicRoadAttributes] = useState<any[]>([]);
  const GetDataBasicMapCitiesParams = {
    attribute_id: attr.id,
    data_id: Number(params.dataId),
    data_attribute_value_id: dataAttributeValue?.id,
  };

  const otherItemParentAttributeId = Common.OtherAttributes.getOtherItemParentAttributeId(attr);
  const selectedOtherItemParentValue = useWatch({
    control,
    name: `attributes.${otherItemParentAttributeId}`,
  });

  const getDicRoadAttributes = useCallback(() => {
    if (dataAttributeValue) {
      var dataAttributeValueId: number | null = null;
      if (dataAttributeValue instanceof Array) {
        dataAttributeValueId = dataAttributeValue[0]?.id;
      } else {
        dataAttributeValueId = dataAttributeValue?.id;
      }

      if (!dataAttributeValueId) return;

      axios
        .get("/api/v1/dicRoadAttributes/getDicRoadAttributes", {
          params: {
            attribute_id: attr.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
          },
        })
        .then((res) => {
          const dicRoadAttributeList = res.data.road_attributes ?? [];
          Promise.all(
            dicRoadAttributeList.map(async (item: any) => {
              const res = await axios.get(
                "/api/v1/dicRoadAttributes/getDicViaMeshes",
                {
                  params: {
                    attribute_id: attr.id,
                    data_id: params.dataId,
                    data_attribute_value_id: dataAttributeValueId,
                    road_attribute_number: item.road_attribute_number,
                    base_data_number: item.base_data_number,
                  },
                }
              );
              return {
                ...item,
                meshList: res.data.via_meshes, // 2次メッシュ
                deleteMeshList: [], // 削除2次メッシュ
              };
            })
          ).then((result) => {
            setDicRoadAttributes(result);
          });
        });
    }
  }, [params.dataId, attr.id, dataAttributeValue]);

  useEffect(() => {
    if (isPId) {
      setValue(`attributes.${attr.id}`, p_id);
    }
  }, [p_id]);

  useEffect(() => {
    if (isRoad && isType !== Config.Data.AttributeCopy) {
      getDicRoadAttributes();
    }
  }, [getDicRoadAttributes, isRoad, isType]);

  useEffect(() => {
    if (!Common.OtherAttributes.showOtherItem(selectedOtherItemParentValue)) {
      setValue(`attributes.${attr.id}`, '')
    }
  }, [selectedOtherItemParentValue])

  if (isOtherItem && !Common.OtherAttributes.showOtherItem(selectedOtherItemParentValue)) {
    return null;
  }

  return (
    <>
      <tr className="">
        <td
          className={`label ${
            (isMapButton || isMapDrawing) && "map-button-active"
          }`}
        >
          {attr.name}
          <span className="collect">{Common.isCollect(attr) && `●`}</span>
          <span className="manage">{Common.isManage(attr) && `●`}</span>
          {required && <span className="error">※</span>}
          {isMapButton && !isMapDrawing && (
            <OpenMapButton attribute={attr} attrValue={attrValue}/>
          )}
          {isRequestedLocation && (<DownloadGeoJsonButton/>)}
          {isMapDrawing && <ViewMapButton attribute={attr}/>}
        </td>
        <td className={`content ${isMapDrawing && "no-padding"}`}>
          {isViaMesh ? (
            <>{meshList.map((item) => item.secondary_mesh_code).join("、")}</>
          ) : isRoad ? (
            <>{roadAttributesCount(dicRoadAttributes)}</>
          ) : (
            chooseComponent(attr, isType)
          )}
        </td>
      </tr>
      {isViaMesh && (
        <RenderMeshAttribute
          ref={meshRef}
          isType={isType}
          attribute={attr}
          dataAttributeValue={dataAttributeValue}
          meshList={meshList}
          setMeshList={setMeshList}
          deleteMeshList={deleteMeshList}
          setDeleteMeshList={setDeleteMeshList}
        />
      )}
      {isRoad && (
        <RenderRoadAttribute
          ref={roadRef}
          attribute={attr}
          dataAttributeValue={dataAttributeValue}
          selectedBlock={selectedBlock}
          baseDataNumberAttribute={baseDataNumberAttribute}
          dicRoadAttributes={dicRoadAttributes}
          setDicRoadAttributes={setDicRoadAttributes}
          deleteDicRoadAttributes={deleteDicRoadAttributes}
          setDeleteDicRoadAttributes={setDeleteDicRoadAttributes}
          getDicRoadAttributes={getDicRoadAttributes}
        />
      )}
      {isUpdatedMunicipalities && (
        <RenderUpdatedMunicipalitiesContainer
          attribute={attr}
          GetDataBasicMapCitiesParams={GetDataBasicMapCitiesParams}
        />
      )}
    </>
  );
};

export type MeshInfo = {
  id: number;
  name: string;
  secondary_mesh_code: string;
};

type MeshAttributeProps = {
  isType: Config.Data;
  attribute: any;
  dataAttributeValue: any;
  meshList: MeshInfo[];
  setMeshList: (meshList: MeshInfo[]) => void;
  deleteMeshList: MeshInfo[];
  setDeleteMeshList: (meshList: MeshInfo[]) => void;
};
const RenderMeshAttribute = forwardRef<
  AttributeInputMethods,
  MeshAttributeProps
>((props, ref) => {
  useImperativeHandle(ref, () => ({
    submit,
  }));
  const params = useParams();
  const [meshInfo, setMeshInfo] = useState<MeshInfo | null>(null);
  const meshCodeRef = useRef<HTMLInputElement>(null);
  const {meshList, deleteMeshList, setMeshList, setDeleteMeshList} = props;
  const [inputError, setInputError] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState<MeshInfo | null>(null);

  const {control} = useFormContext();
  const selectedArea = useWatch({
    control,
    name: "area_id",
  });

  const {fetchSecondaryMesh} = useMeshAttribute();
  const onChange = useCallback(async (text: string) => {
    setInputError(false);
    if (text.length === 6) {
      await fetchSecondaryMesh({code: text, area_id: selectedArea, setMeshInfo})
    }
  }, [selectedArea]);

  const onClickAdd = useCallback(() => {
    if (meshCodeRef?.current?.value === "") {
      setInputError(true);
      return;
    }

    if (
      !meshInfo ||
      meshList.find(
        (item) => item.secondary_mesh_code === meshInfo.secondary_mesh_code
      )
    ) {
      return;
    }

    setMeshList(meshList.concat(meshInfo));
    setMeshInfo(null);
    meshCodeRef.current?.value && (meshCodeRef.current.value = "");
  }, [meshInfo, meshList, setMeshList]);

  const onClickDelete = useCallback((mesh: MeshInfo) => {
    setVisibleConfirm(mesh);
  }, []);

  const onDelete = useCallback(() => {
    if (!visibleConfirm) return;

    setMeshList(
      meshList.filter(
        (m) => m.secondary_mesh_code !== visibleConfirm.secondary_mesh_code
      )
    );
    if (visibleConfirm.id !== -1) {
      setDeleteMeshList(deleteMeshList.concat(visibleConfirm));
    }
    setVisibleConfirm(null);
  }, [
    deleteMeshList,
    meshList,
    setDeleteMeshList,
    setMeshList,
    visibleConfirm,
  ]);

  const getMeshList = useCallback(() => {
    if (props.dataAttributeValue) {
      var dataAttributeValueId = null;
      if (props.dataAttributeValue instanceof Array) {
        dataAttributeValueId = props.dataAttributeValue[0]?.id;
      } else {
        dataAttributeValueId = props.dataAttributeValue?.id;
      }

      if (!dataAttributeValueId) return;

      axios
        .get("/api/v1/dicRoadAttributes/getDicViaMeshes", {
          params: {
            attribute_id: props.attribute.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
          },
        })
        .then((res) => {
          setMeshList(res.data.via_meshes);
        });
    }
  }, [
    params.dataId,
    props.attribute.id,
    props.dataAttributeValue,
    setMeshList,
  ]);

  const submit = useCallback(async () => {
    if (meshList.length === 0) return;

    try {
      await Promise.all([
        meshList
          .filter((item) => item.id === -1)
          .map(async (mesh) => {
            await axios.post("/api/v1/dicRoadAttributes/postDicViaMeshes", {
              attribute_id: props.attribute.id,
              data_id: params.dataId,
              data_attribute_value_id: props.dataAttributeValue?.id,
              secondary_mesh_code: mesh.secondary_mesh_code,
            });
          }),
        ...deleteMeshList.map((mesh) =>
          axios.post("/api/v1/dicRoadAttributes/deleteDicViaMeshes", {
            id: mesh.id,
          })
        ),
      ]);
      setDeleteMeshList([]);
      getMeshList();
    } catch (e) {
      console.log(e);
    }
  }, [
    deleteMeshList,
    getMeshList,
    meshList,
    params.dataId,
    props.attribute.id,
    props.dataAttributeValue?.id,
    setDeleteMeshList,
  ]);

  useEffect(() => {
    if (props.isType !== Config.Data.AttributeCopy) {
      getMeshList();
    }
  }, [getMeshList, props.dataAttributeValue, props.isType]);

  return (
    <tr className="">
      <td
        colSpan={2}
        style={{
          padding: 24,
          borderLeft: "none",
          borderRight: "none",
          backgroundColor: "white",
        }}
      >
        <div
          className=""
          style={{
            border: "1px solid #92989a",
            padding: 24,
          }}
        >
          <div className="uk-flex uk-flex-center gap-10">
            <div className="">2次メッシュ番号</div>
            <div className="uk-flex uk-flex-column">
              <input
                className={`${inputError ? "error-form" : ""}`}
                ref={meshCodeRef}
                onChange={(e) => onChange(e.target.value)}
              />
              {inputError && (
                <span className="error">2次メッシュ番号は必須項目です。</span>
              )}
            </div>

            <div className="">
              <input
                value={meshInfo?.name ?? ""}
                style={{
                  backgroundColor: "#efefef",
                }}
                disabled
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickAdd();
              }}
              className="uk-button--m uk-button-refer uk-margin-left"
            >
              追加
            </button>
          </div>

          {meshList.length > 0 && (
            <div className="mt-32 uk-width-1-2 uk-margin-auto">
              <div className="">
                <table className="uk-table-small uk-table-middle">
                  <thead>
                  <tr>
                    <th className="uk-text-center">削除</th>
                    <th className="uk-text-center">No</th>
                    <th className="uk-text-center">2次メッシュ番号</th>
                    <th className="uk-text-center">名称</th>
                  </tr>
                  </thead>
                  <tbody>
                  {meshList.map((mesh, idx) => (
                    <tr key={`mesh_${idx}`}>
                      <td className="uk-text-center width-40 white">
                        <a onClick={() => onClickDelete(mesh)}>削除</a>
                      </td>
                      <td className="uk-text-center width-60">{idx + 1}</td>
                      <td className="uk-text-left width-200 white">
                        {mesh.secondary_mesh_code}
                      </td>
                      <td className="uk-text-left white">{mesh.name}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </td>

      <ConfirmModal
        text={Config.MESSAGE_NO_E11}
        confirmButtonText="削除"
        isShow={visibleConfirm != null}
        onConfirm={onDelete}
        onCancel={() => setVisibleConfirm(null)}
      />
    </tr>
  );
});

type MeshChildAttributeProps = {
  dicRoadAttribute: any;
  setDicRoadAttribute: (roadAttribute: any) => void;
};
const RenderChildMeshAttribute = (props: MeshChildAttributeProps) => {
  const [meshInfo, setMeshInfo] = useState<MeshInfo | null>(null);
  const meshCodeRef = useRef<HTMLInputElement>(null);
  const [inputError, setInputError] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState<MeshInfo | null>(null);

  const {control} = useFormContext();
  const selectedArea = useWatch({
    control,
    name: "area_id",
  });
  const {fetchSecondaryMesh} = useMeshAttribute();

  const onChange = useCallback(async (text: string) => {
    setInputError(false);
    if (text.length === 6) {
      await fetchSecondaryMesh({code: text, area_id: selectedArea, setMeshInfo})
    }
  }, [selectedArea]);

  const onClickAdd = useCallback(() => {
    if (meshCodeRef?.current?.value === "") {
      setInputError(true);
      return;
    }

    if (
      !meshInfo ||
      (props.dicRoadAttribute?.meshList ?? []).find(
        (item: MeshInfo) =>
          item.secondary_mesh_code === meshInfo.secondary_mesh_code
      )
    ) {
      return;
    }

    const newMeshList = [...(props.dicRoadAttribute?.meshList ?? []), meshInfo];
    props.setDicRoadAttribute({
      ...props.dicRoadAttribute,
      meshList: newMeshList,
    });
    setMeshInfo(null);
    meshCodeRef.current?.value && (meshCodeRef.current.value = "");
  }, [meshInfo, props]);

  const onClickDelete = useCallback((mesh: MeshInfo) => {
    setVisibleConfirm(mesh);
  }, []);

  const onDelete = useCallback(() => {
    if (!visibleConfirm) return;

    const newMeshList = (props.dicRoadAttribute?.meshList ?? []).filter(
      (m: MeshInfo) =>
        m.secondary_mesh_code !== visibleConfirm.secondary_mesh_code
    );
    var deleteMeshList = props.dicRoadAttribute?.deleteMeshList ?? [];
    if (visibleConfirm.id !== -1) {
      deleteMeshList = [...deleteMeshList, visibleConfirm];
    }
    props.setDicRoadAttribute({
      ...props.dicRoadAttribute,
      meshList: newMeshList,
      deleteMeshList: deleteMeshList,
    });

    setVisibleConfirm(null);
  }, [props, visibleConfirm]);

  return (
    <tr className="">
      <td
        colSpan={2}
        style={{
          padding: 24,
          borderLeft: "none",
          borderRight: "none",
          backgroundColor: "white",
        }}
      >
        <div
          className=""
          style={{
            border: "1px solid #92989a",
            padding: 24,
          }}
        >
          <div className="uk-flex uk-flex-center gap-10">
            <div className="">2次メッシュ番号</div>
            <div className="uk-flex uk-flex-column">
              <input
                className={`${inputError ? "error-form" : ""}`}
                ref={meshCodeRef}
                onChange={(e) => onChange(e.target.value)}
              />
              {inputError && (
                <span className="error">2次メッシュ番号は必須項目です。</span>
              )}
            </div>

            <div className="">
              <input
                value={meshInfo?.name ?? ""}
                style={{
                  backgroundColor: "#efefef",
                }}
                disabled
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickAdd();
              }}
              className="uk-button--m uk-button-refer uk-margin-left"
            >
              追加
            </button>
          </div>

          {(props.dicRoadAttribute?.meshList ?? []).length > 0 && (
            <div className="mt-32 uk-width-1-2 uk-margin-auto">
              <div className="">
                <table className="uk-table-small uk-table-middle">
                  <thead>
                  <tr>
                    <th className="uk-text-center">削除</th>
                    <th className="uk-text-center">No</th>
                    <th className="uk-text-center">2次メッシュ番号</th>
                    <th className="uk-text-center">名称</th>
                  </tr>
                  </thead>
                  <tbody>
                  {(props.dicRoadAttribute?.meshList ?? []).map(
                    (mesh: MeshInfo, idx: number) => (
                      <tr key={`mesh_${idx}`}>
                        <td className="uk-text-center width-40 white">
                          <a onClick={() => onClickDelete(mesh)}>削除</a>
                        </td>
                        <td className="uk-text-center width-60">{idx + 1}</td>
                        <td className="uk-text-left width-200 white">
                          {mesh.secondary_mesh_code}
                        </td>
                        <td className="uk-text-left white">{mesh.name}</td>
                      </tr>
                    )
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </td>

      <ConfirmModal
        text={Config.MESSAGE_NO_E11}
        confirmButtonText="削除"
        isShow={visibleConfirm != null}
        onConfirm={onDelete}
        onCancel={() => setVisibleConfirm(null)}
      />
    </tr>
  );
};

type RoadAttributeProps = {
  attribute: any;
  dataAttributeValue: any;
  selectedBlock: any;
  baseDataNumberAttribute: any;
  dicRoadAttributes: any[];
  setDicRoadAttributes: (dicRoadAttributes: any[]) => void;
  deleteDicRoadAttributes: any[];
  setDeleteDicRoadAttributes: (deleteDicRoadAttributes: any[]) => void;
  getDicRoadAttributes: () => void;
};
const RenderRoadAttribute = forwardRef<
  AttributeInputMethods,
  RoadAttributeProps
>((props, ref) => {
  const {
    dicRoadAttributes,
    setDicRoadAttributes,
    deleteDicRoadAttributes,
    setDeleteDicRoadAttributes,
    getDicRoadAttributes,
  } = props;
  const [visibleConfirm, setVisibleConfirm] = useState<any>(null);

  const params = useParams();
  useImperativeHandle(ref, () => ({
    submit,
  }));
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    trigger,
    formState: {errors},
  } = useForm();

  const [curDicRoadAttribute, setCurDicRoadAttribute] = useState({
    id: -1,
    meshList: [],
    deleteMeshList: [],
  });
  const [roadStructures, setRoadStructures] = useState<
    {
      id: number;
      value: string;
    }[]
  >([]);
  const [constructDetails, setConstructDetails] = useState<
    {
      id: number;
      value: string;
    }[]
  >([]);
  const [cities, setCities] = useState<
    {
      code: number;
      name: string;
    }[]
  >([]);
  const [dicAdoptions, setDicAdoptions] = useState<
    {
      id: number;
      name: string;
      exclusion_flag: boolean;
    }[]
  >([]);
  const [dicRanks, setDicRanks] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [dicReasonRanks, setDicReasonRanks] = useState<
    {
      code: string;
      name: string;
    }[]
  >([]);

  const onClear = useCallback(() => {
    clearErrors();
    setValue("road_attribute_number", null);
    setValue("base_data_number", null);
    setValue("attribute_management_number", null);
    setValue("road_structures_code", null);
    setValue("construction_details_code", null);
    setValue("attribute_name", null);
    setValue("reading_name", null);
    setValue("attribute_extension", null);
    setValue("administrative_code_start_point", null);
    setValue("administrative_code_end_point", null);
    setValue("adoption_or_not", "");
    setValue("rank", null);
    setValue("repair_flag", null);
    setValue("reason_for_ranking", null);
    setValue("note", null);
    setCurDicRoadAttribute({
      id: -1,
      meshList: [],
      deleteMeshList: [],
    });
  }, [clearErrors, setValue]);

  const onPressAdd = useCallback(async () => {
    const validated = await trigger();
    if (validated) {
      const data = getValues();
      if (data.id) {
        setDicRoadAttributes(
          dicRoadAttributes.map((item) =>
            item.id === data.id
              ? {
                ...data,
                meshList: item.meshList ?? [],
                deleteMeshList: item.deleteMeshList ?? [],
              }
              : item
          )
        );
      } else {
        setDicRoadAttributes(
          dicRoadAttributes.concat([
            {
              id: -1,
              ...data,
              adoption_or_not:
                data.adoption_or_not === ""
                  ? dicAdoptions[0].id
                  : data.adoption_or_not,
              meshList: curDicRoadAttribute?.meshList ?? [],
              deleteMeshList: curDicRoadAttribute?.deleteMeshList ?? [],
            },
          ])
        );
      }
      onClear();
    }
  }, [
    curDicRoadAttribute?.deleteMeshList,
    curDicRoadAttribute?.meshList,
    dicAdoptions,
    dicRoadAttributes,
    getValues,
    onClear,
    setDicRoadAttributes,
    trigger,
  ]);

  const onClickDelete = useCallback((dicRoadAttribute: any) => {
    setVisibleConfirm(dicRoadAttribute);
  }, []);

  const onDelete = useCallback(() => {
    if (!visibleConfirm) return;

    setDicRoadAttributes(dicRoadAttributes.filter((m) => m !== visibleConfirm));
    if (visibleConfirm.id !== -1) {
      setDeleteDicRoadAttributes(
        deleteDicRoadAttributes.concat(visibleConfirm)
      );
    }
    setVisibleConfirm(null);
    onClear();
  }, [
    deleteDicRoadAttributes,
    dicRoadAttributes,
    onClear,
    setDeleteDicRoadAttributes,
    setDicRoadAttributes,
    visibleConfirm,
  ]);

  const onClickEdit = useCallback(
    (dicRoadAttribute: any) => {
      clearErrors();
      setValue("id", dicRoadAttribute.id);
      setValue(
        "attribute_management_number",
        dicRoadAttribute.attribute_management_number
      );
      setValue("road_structures_code", dicRoadAttribute.road_structures_code);
      setValue(
        "construction_details_code",
        dicRoadAttribute.construction_details_code
      );
      setValue("attribute_name", dicRoadAttribute.attribute_name);
      setValue("reading_name", dicRoadAttribute.reading_name);
      setValue("attribute_extension", dicRoadAttribute.attribute_extension);
      setValue(
        "administrative_code_start_point",
        dicRoadAttribute.administrative_code_start_point
      );
      setValue(
        "administrative_code_end_point",
        dicRoadAttribute.administrative_code_end_point
      );
      setValue("adoption_or_not", dicRoadAttribute.adoption_or_not);
      setValue("rank", dicRoadAttribute.rank);
      setValue("repair_flag", dicRoadAttribute.repair_flag);
      setValue("reason_for_ranking", dicRoadAttribute.reason_for_ranking);
      setValue("note", dicRoadAttribute.note);
      setValue("road_attribute_number", dicRoadAttribute.road_attribute_number);
      setValue("base_data_number", dicRoadAttribute.base_data_number);
      setCurDicRoadAttribute(dicRoadAttribute);
    },
    [clearErrors, setValue]
  );

  const submit = useCallback(async () => {
    var dataAttributeValueId: any = null;
    if (props.dataAttributeValue instanceof Array) {
      dataAttributeValueId = props.dataAttributeValue[0]?.id;
    } else {
      dataAttributeValueId = props.dataAttributeValue?.id;
    }

    try {
      for (const dicRoadAttribute of dicRoadAttributes) {
        const res = await axios.post(
          "/api/v1/dicRoadAttributes/postDicRoadAttributes",
          {
            ...dicRoadAttribute,
            id: dicRoadAttribute.id === -1 ? undefined : dicRoadAttribute.id,
            adoption_or_not:
              dicRoadAttribute.adoption_or_not === ""
                ? dicAdoptions[0].id
                : dicRoadAttribute.adoption_or_not,
            attribute_id: props.attribute?.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
            base_data_number: props.baseDataNumberAttribute?.attribute_value,
          }
        );
        const {dic_road_attribute} = res.data;

        for (const mesh of (dicRoadAttribute.meshList ?? []).filter(
          (item: any) => item.id === -1
        )) {
          await axios.post("/api/v1/dicRoadAttributes/postDicViaMeshes", {
            attribute_id: props.attribute?.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
            secondary_mesh_code: mesh.secondary_mesh_code,
            road_attribute_number: dic_road_attribute.road_attribute_number,
            base_data_number: dic_road_attribute.base_data_number,
            is_parent: false,
          });
        }

        await Promise.all(
          (dicRoadAttribute.deleteMeshList ?? []).map(async (mesh: any) => {
            await axios.post("/api/v1/dicRoadAttributes/deleteDicViaMeshes", {
              id: mesh.id,
            });
          })
        );
      }

      await Promise.all(
        deleteDicRoadAttributes.map((dicRoadAttribute) =>
          axios.post("/api/v1/dicRoadAttributes/deleteDicRoadAttributes", {
            id: dicRoadAttribute.id,
          })
        )
      );

      setDeleteDicRoadAttributes([]);
      getDicRoadAttributes();
    } catch (e) {
    }
  }, [
    deleteDicRoadAttributes,
    dicAdoptions,
    dicRoadAttributes,
    getDicRoadAttributes,
    params.dataId,
    props.attribute?.id,
    props.baseDataNumberAttribute?.attribute_value,
    props.dataAttributeValue,
    setDeleteDicRoadAttributes,
  ]);

  const roadStructureIdValue = (dicRoadAttribute: any) => {
    const roadStructure = roadStructures.find(
      (item) =>
        Number(item.id) === Number(dicRoadAttribute.road_structures_code)
    );
    return roadStructure
      ? `${String(roadStructure.id).padStart(2, "0")}: ${roadStructure.value}`
      : "";
  };

  useEffect(() => {
    if (props.selectedBlock) {
      Promise.all([
        axios.get("/api/v1/dicRoadAttributes/getDicRoadStructures"),
        axios.get("/api/v1/dicRoadAttributes/getDicConstructionDetails"),
        axios.get("/api/v1/dicRoadAttributes/getDicCities", {
          params: {
            block_id: props.selectedBlock,
          },
        }),
        axios.get("/api/v1/dicRoadAttributes/getDicAdoptions"),
        axios.get("/api/v1/dicRoadAttributes/getDicRanks"),
        axios.get("/api/v1/dicRoadAttributes/getDicReasonForRankings"),
      ]).then((res) => {
        setRoadStructures(res[0].data.road_structures ?? []);
        setConstructDetails(res[1].data.construction_details ?? []);
        setCities(res[2].data.cities ?? []);
        setDicAdoptions(res[3].data.adoptions ?? []);
        setDicRanks(res[4].data.ranks ?? []);
        setDicReasonRanks(res[5].data.reason_for_rankings ?? []);
      });
    }
  }, [props.selectedBlock]);

  useEffect(() => {
    if (
      curDicRoadAttribute.id !== -1 &&
      dicRoadAttributes.find((item) => item.id === curDicRoadAttribute.id)
    ) {
      setCurDicRoadAttribute(
        dicRoadAttributes.find((item) => item.id === curDicRoadAttribute.id)
      );
    }
  }, [curDicRoadAttribute.id, dicRoadAttributes]);

  return (
    <tr className="">
      <td
        colSpan={2}
        style={{
          padding: 24,
          borderLeft: "none",
          borderRight: "none",
          backgroundColor: "white",
        }}
      >
        <div
          className=""
          style={{
            border: "1px solid #92989a",
            padding: 24,
          }}
        >
          <div className="mt-32">
            <div className="">
              <table className="uk-table-small uk-table-middle">
                <thead>
                <tr>
                  <th className="uk-text-center">削除</th>
                  <th className="uk-text-center">編集</th>
                  <th className="uk-text-center">道路属性整理番号</th>
                  <th className="uk-text-center">基本データ対応番号</th>
                  <th className="uk-text-center">属性管理番号</th>
                  <th className="uk-text-center">道路構造</th>
                  <th className="uk-text-center">工事内容</th>
                  <th className="uk-text-center">属性名</th>
                </tr>
                </thead>
                <tbody>
                {dicRoadAttributes.map((dicRoadAttribute, idx) => (
                  <tr key={`road_attribute_${idx}`}>
                    <td
                      className="uk-text-center"
                      style={{width: 40, backgroundColor: "white"}}
                    >
                      <a onClick={() => onClickDelete(dicRoadAttribute)}>
                        削除
                      </a>
                    </td>
                    <td
                      className="uk-text-center"
                      style={{width: 40, backgroundColor: "white"}}
                    >
                      <a onClick={() => onClickEdit(dicRoadAttribute)}>
                        編集
                      </a>
                    </td>
                    <td
                      className="uk-text-center"
                      style={{backgroundColor: "white"}}
                    >
                      {dicRoadAttribute.road_attribute_number}
                    </td>
                    <td
                      className="uk-text-center"
                      style={{width: 200, backgroundColor: "white"}}
                    >
                      {dicRoadAttribute.base_data_number}
                    </td>
                    <td
                      className="uk-text-center"
                      style={{backgroundColor: "white"}}
                    >
                      {dicRoadAttribute.attribute_management_number}
                    </td>
                    <td
                      className="uk-text-center"
                      style={{backgroundColor: "white"}}
                    >
                      {roadStructureIdValue(dicRoadAttribute)}
                    </td>
                    <td
                      className="uk-text-center"
                      style={{backgroundColor: "white"}}
                    >
                      {
                        constructDetails.find(
                          (item) =>
                            Number(item.id) ===
                            Number(dicRoadAttribute.construction_details_code)
                        )?.value
                      }
                    </td>
                    <td
                      className="uk-text-center"
                      style={{backgroundColor: "white", width: 250}}
                    >
                      {dicRoadAttribute.attribute_name}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-32">
            <div className="">
              <table className="uk-table-small uk-table-middle">
                <tbody>
                <tr>
                  <td className="label">道路属性整理番号</td>
                  <td className="content">自動採番</td>
                </tr>
                <tr>
                  <td className="label">基本データ対応番号</td>
                  <td className="content">自動採番</td>
                </tr>
                <tr>
                  <td className="label">
                    属性管理番号<span className="error">※</span>
                  </td>
                  <td className="content">
                    <input
                      className={`uk-width-1-1 ${
                        errors.attribute_management_number ? "error-form" : ""
                      }`}
                      type="text"
                      minLength={11}
                      maxLength={11}
                      {...register("attribute_management_number", {
                        required: true,
                        maxLength: 11,
                        minLength: 11,
                      })}
                    />
                    {errors.attribute_management_number && (
                      <p className="error">{Config.MESSAGE_NO_E68(11)}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    道路構造<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={roadStructures.map((o) => ({
                        label: `${o.id}: ${o.value}`,
                        value: String(o.id),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="road_structures_code"
                      error={errors.road_structures_code?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    工事内容<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={constructDetails.map((o) => ({
                        label: `${o.id}: ${o.value}`,
                        value: String(o.id),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="construction_details_code"
                      error={errors.construction_details_code?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    属性名<span className="error">※</span>
                  </td>
                  <td className="content">
                    <input
                      className={`uk-width-1-1 ${
                        errors.attribute_name ? "error-form" : ""
                      }`}
                      type="text"
                      {...register("attribute_name", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                    {errors.attribute_name && (
                      <p className="error">
                        {errors.attribute_name.type === "maxLength"
                          ? Config.MESSAGE_NO_E35(100)
                          : Config.MESSAGE_NO_E08}
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="label">属性名（カナ名）</td>
                  <td className="content">
                    <input
                      className={`uk-width-1-1 ${
                        errors.reading_name ? "error-form" : ""
                      }`}
                      type="text"
                      {...register("reading_name", {
                        required: false,
                        maxLength: 100,
                      })}
                    />
                    {errors.reading_name && (
                      <p className="error">
                        {errors.reading_name.type === "maxLength"
                          ? Config.MESSAGE_NO_E35(100)
                          : Config.MESSAGE_NO_E08}
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="label">属性延長</td>
                  <td className="content">
                    <input
                      className="uk-width-1-1"
                      type="text"
                      {...register("attribute_extension", {
                        required: false,
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">経由メッシュ</td>
                  <td className="content">
                    {(curDicRoadAttribute.meshList ?? [])
                      .map((item: any) => item.secondary_mesh_code)
                      .join("、")}
                  </td>
                </tr>
                <RenderChildMeshAttribute
                  dicRoadAttribute={curDicRoadAttribute}
                  setDicRoadAttribute={(roadAttribute) => {
                    if (curDicRoadAttribute.id === -1) {
                      setCurDicRoadAttribute({
                        ...curDicRoadAttribute,
                        meshList: roadAttribute.meshList ?? [],
                        deleteMeshList: roadAttribute.deleteMeshList ?? [],
                      });
                    } else {
                      const newList = dicRoadAttributes.map((item) =>
                        item.id === roadAttribute.id ? roadAttribute : item
                      );
                      setDicRoadAttributes(newList);
                    }
                  }}
                />
                <tr>
                  <td className="label">
                    起点側市町村名<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={cities.map((o) => ({
                        label: `${o.code}: ${o.name}`,
                        value: String(o.code),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="administrative_code_start_point"
                      error={errors.administrative_code_start_point?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    終点側市町村名<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={cities.map((o) => ({
                        label: `${o.code}: ${o.name}`,
                        value: String(o.code),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="administrative_code_end_point"
                      error={errors.administrative_code_end_point?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">出力フラグ</td>
                  <td className="content">
                    <input
                      className="uk-width-1-1"
                      type="checkbox"
                      {...register("output_flag", {required: false})}
                    />
                    <span>1: チェック</span>
                  </td>
                </tr>
                <tr>
                  <td className="label">採択の可否</td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={dicAdoptions.map((o) => ({
                        label: `${o.id}: ${o.name}`,
                        value: String(o.id),
                      }))}
                      rules={{required: false}}
                      name="adoption_or_not"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    ランク<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={dicRanks.map((o) => ({
                        label: `${o.id}: ${o.name}`,
                        value: String(o.id),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="rank"
                      error={errors.rank?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">補修フラグ</td>
                  <td className="content">
                    <input
                      className="uk-width-1-1"
                      type="checkbox"
                      {...register("repair_flag", {required: false})}
                    />
                    <span>1: チェック</span>
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    ランク付与理由コード<span className="error">※</span>
                  </td>
                  <td className="content">
                    <SelectBox
                      className="uk-width-1-1"
                      register={register}
                      options={dicReasonRanks.map((o) => ({
                        label: `${Number(o.code).toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}: ${o.name}`,
                        value: String(o.code),
                      }))}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      name="reason_for_ranking"
                      error={errors.reason_for_ranking?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">備考</td>
                  <td className="content">
                      <textarea
                        className={`uk-width-1-1 ${
                          errors.note ? "error-form" : ""
                        }`}
                        {...register("note", {
                          required: false,
                          maxLength: 100,
                        })}
                      />
                    {errors.note && (
                      <p className="error">
                        {errors.note.type === "maxLength"
                          ? Config.MESSAGE_NO_E35(100)
                          : Config.MESSAGE_NO_E08}
                      </p>
                    )}
                  </td>
                </tr>
                </tbody>
              </table>

              <div className="uk-flex uk-flex-center gap-10 mt-32">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onClear();
                  }}
                  className="uk-button--m uk-button-cancel"
                >
                  クリア
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onPressAdd();
                  }}
                  className="uk-button--m uk-button-refer uk-margin-left"
                >
                  追加
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>

      <ConfirmModal
        text={Config.MESSAGE_NO_E11}
        confirmButtonText="削除"
        isShow={visibleConfirm != null}
        onConfirm={onDelete}
        onCancel={() => setVisibleConfirm(null)}
      />
    </tr>
  );
});

interface AttributeInputBaseProps {
  isType: Config.Data;
  selectedProjectCategory: any;
  attributes: Array<any>;
  dataMap: Map<string, any> | undefined;
  areas: Array<any>;
  blocks: Array<any>;
  areaName: string;
  blockName: string;
  meshRef: any;
  roadRef: any;
}

const InputDefaultRows = ({
                            isType,
                            selectedProjectCategory,
                            attributes,
                            dataMap,
                            areas,
                            blocks,
                            areaName,
                            blockName,
                            meshRef,
                            roadRef,
                          }: AttributeInputBaseProps) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors, isDirty},
  } = useFormContext();

  const selectedArea = useWatch({
    control,
    name: "area_id",
  });
  const selectedBlock = useWatch({
    control,
    name: "block_id",
  });

  const filteredBlocks = useMemo(() => {
    if (selectedArea)
      return blocks.filter(
        (block: any) => block.area_id === parseInt(selectedArea)
      );
    else return [];
  }, [selectedArea]);

  useEffect(() => {
    if (isDirty) {
      setValue("block_id", "");
    }
  }, [selectedArea]);

  const isHiddenArea = selectedProjectCategory?.category_code === Config.CATEGORY_CODE_FAQ;

  const isHiddenBlock = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;

  const isDisablesArea = isType == Config.Data.AttributeEdit && isHiddenBlock;

  const baseDataNumberAttribute = attributes.find(
    (item) => item.column_name === Config.ATTRIBUTE_COLUMN_NAME.baseNumber
  );
  const attibuteValue = getValues(`attributes.${attributes[0].id}`);

  const isAutoIncriment = Common.isAutoIncriment(attributes[0]);

  const isEdit = isType == Config.Data.AttributeEdit;

  return (
    <>
      <tr className="">
        <td className="label" key={`input_td_1_${attributes[0].id}`}>
          {attributes[0].name}
          <span className="collect">{Common.isCollect(attributes[0]) && `●`}</span>
          <span className="manage">{Common.isManage(attributes[0]) && `●`}</span>
          <span className="error">※</span>
        </td>
        <td className="content" key={`input_td_2_${attributes[0].id}`}>
          {isAutoIncriment && !isEdit && `自動採番`}
          {isAutoIncriment && isEdit && attibuteValue}
          {!isAutoIncriment && chooseComponent(attributes[0], isType)}
        </td>
      </tr>
      <tr className="" style={{display: isHiddenArea ? "none" : ""}}>
        <td className="label" key={`input_td_1_area`}>
          {areaName}
          <span className="error">※</span>
        </td>
        <td className="content" key={`input_td_2_area`}>
          <select
            className={`uk-width-1-1 ${selectedArea ? "" : "placeholder"} ${
              errors.area_id ? "error-form" : ""
            }`}
            defaultValue={""}
            {...register("area_id", {required: "必須項目です"})}
            disabled={isDisablesArea}
          >
            <option value="" disabled>
              エリア選択
            </option>
            {areas.map((o: any) => {
              return (
                <option key={`area_id_${o.id}`} value={o.id}>
                  {o.code + ": " + o.name}
                </option>
              );
            })}
          </select>
          {errors.area_id && (
            <div className="error">{`${errors.area_id.message}`}</div>
          )}
        </td>
      </tr>
      <tr
        className=""
        style={{display: isHiddenArea || isHiddenBlock ? "none" : ""}}
      >
        <td className="label" key={`input_td_1_block`}>
          {blockName}
          <span className="error">※</span>
        </td>
        <td className="content" key={`input_td_2_block`}>
          <select
            className={`uk-width-1-1 ${selectedBlock ? "" : "placeholder"} ${
              errors.block_id ? "error-form" : ""
            }`}
            defaultValue={""}
            {...register("block_id", {required: "必須項目です"})}
          >
            <option value="" disabled>
              ブロック選択
            </option>
            {filteredBlocks.map((o: any) => {
              return (
                <option key={`block_id_${o.id}`} value={o.id}>
                  {o.code + ": " + o.name}
                </option>
              );
            })}
          </select>
          {errors.block_id && (
            <div className="error">{`${errors.block_id.message}`}</div>
          )}
        </td>
      </tr>
      {attributes.slice(1).map((attribute) => (
        <RenderRow
          isType={isType}
          attr={attribute}
          dataAttributeValue={dataMap?.get(attribute.id)}
          selectedBlock={selectedBlock}
          meshRef={meshRef}
          roadRef={roadRef}
          baseDataNumberAttribute={dataMap?.get(baseDataNumberAttribute?.id)}
        />
      ))}
    </>
  );
};

const InputRows = ({
                     isType,
                     attributes,
                     meshRef,
                   }: {
  isType: Config.Data;
  attributes: Array<any>;
  meshRef: any;
}) => {
  return (
    <>
      {attributes.map((attribute) => (
        <RenderRow isType={isType} attr={attribute}/>
      ))}
    </>
  );
};

// const SimpleRows = ({attributes}: { attributes: Array<any> }) => {
//   return (
//     <>
//       {attributes.map((attr, index) => (
//         <tr className="">
//           <td key={`inputBox_td_1_${attr.id}`} className="uk-width-1-4">
//             {attr.name}
//           </td>
//           <td key={`inputBox_td_2_${attr.id}`}>{chooseComponent(attr)}</td>
//         </tr>
//       ))}
//     </>
//   );
// };

const roadAttributesCount = (roadAttributes: any[]) => {
  const totalCount = roadAttributes.length;
  const availableCount = roadAttributes.filter(
    (item) =>
      Number(item.adoption_or_not) ===
      Number(Config.COUNTABLE_ADOPTION_OR_NOT_OPTION)
  ).length;
  return `${availableCount}/${totalCount}`;
};

const chooseComponent = (attribute: any, isType: Config.Data) => {
  const DISABLED_COLUMNS = [
    Config.ATTRIBUTE_COLUMN_NAME.updatedMunicipalities,
    Config.ATTRIBUTE_COLUMN_NAME.itemNumber,
    Config.ATTRIBUTE_COLUMN_NAME.topographicMapName
  ];
  const disabled = (Common.isNotEdit(attribute, isType)) || DISABLED_COLUMNS.includes(attribute.column_name);
  if (
    attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.viaMesh ||
    attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.road
  ) {
    return <div></div>;
  }
  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_FIELD:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_INT:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL:
      return (
        <Attributes.InputText
          attribute={attribute}
          props={{className: "uk-width-1-1", disabled: disabled}}
        />
      );
    case Config.ATTRIBUTE_KINDS_CODE_TEXT:
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_AREA:
      return (
        <Attributes.InputTextArea
          attribute={attribute}
          props={{className: "uk-width-1-1"}}
        />
      );
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      const [parentName, filterName] = Attributes.hasRelation(attribute);
      if (parentName && filterName)
        return (
          <Attributes.HasRelation
            attribute={attribute}
            parentName={parentName}
            filterName={filterName}
            props={{className: "uk-width-1-1"}}
          />
        );
      else
        return (
          <Attributes.NoneRelation
            attribute={attribute}
            props={{className: "uk-width-1-1"}}
          />
        );
    case Config.ATTRIBUTE_KINDS_CODE_DATE:
      return (
        <Attributes.InputText
          attribute={attribute}
          props={{
            type: "date",
            className: "uk-width-1-1",
          }}
        />
      );
    case Config.ATTRIBUTE_KINDS_CODE_MAP_DRAWING:
      return (
        <Attributes.InputMapDrawing
          attribute={attribute}
          props={{
            className: "uk-width-1-1",
          }}
        />
      );
    case Config.ATTRIBUTE_KINDS_CODE_YEAR_MONTH:
      return (
        <Attributes.InputText
          attribute={attribute}
          props={{
            type: "month",
            className: "uk-width-1-1",
            disabled: disabled,
          }}
        />
      );
  }
};
