import React from "react";
import {useMapDrawing} from "@/stores/mapDrawing";

export const DownloadGeoJsonButton = () => {
  const {coordinate} = useMapDrawing();
  const onClickDownload = () => {
    if (!coordinate) return;
    const jsonString = JSON.stringify(coordinate);
    const blob = new Blob([jsonString], {type: "application/geo+json"});

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "coordinate.geojson";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  return (
    <button
      type="button"
      className="uk-button--w uk-button-refer uk-position-absolute uk-position-bottom-center uk-margin-bottom"
      disabled={!coordinate}
      onClick={onClickDownload}
    >
      GeoJsonダウンロード
    </button>
  )
}