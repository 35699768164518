import React, {useEffect, useState} from "react";
import {MapDrawing} from "@/components/Form/Attributes";
import {useParams} from "react-router-dom";
import {axios} from '@/lib/axios';
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";
import {MESSAGE_NO_E39} from "@/config";

interface DataAttribute {
  id: number;
  attribute_id: number;
  data_id: number;
  column_name: string;
  value: string;
}

interface Datas {
  id: number;
  data_attribute_values: DataAttribute[];
  data_attribute_text_values: DataAttribute[];
}

export const NaviAnswer = () => {
  const token = useParams().param;
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [data, setData] = useState<Datas>({} as Datas);

  useEffect(() => {
    setLoading(true);
    axios.get('/api/v1/attributes/getPublicNaviList', {params: {token: token}}
    ).then(result => {
      const datas = result.data.datas;
      setData(datas);
    }).catch(error => setMessage(MESSAGE_NO_E39)
    ).finally(() => setLoading(false))
  }, [])

  if (!data.data_attribute_text_values) return null;
  return (
    <div className="navi-answer-container">
      <div className="sub-header-title">一覧</div>
      <div className="navi-answer-tbl">
        <table className="uk-width-1-1 uk-margin-bottom">
          <thead>
          <tr className="">
            {companies.map(company => (
              <td className="label">{company.label}</td>
            ))}
          </tr>
          </thead>
          <tbody>
          <tr className="">
            {companies.map(company => {
              const content = data.data_attribute_values?.find(value => value.column_name === company.column_name)?.value ?? '';
              return <td className="content">{content}</td>
            })}
          </tr>
          </tbody>
        </table>
      </div>
      <div className="info-tbl">
        <table className="uk-width-1-1">
          <tbody>
          {coordinate.map(company => {
            const content = data.data_attribute_text_values?.find(value => value.column_name === company.column_name)?.value ?? '';
            const isMapDrawing = company.column_name === 'requested_location';
            console.log(content)
            return (
              <tr className="">
                <td className="label">{company.label}</td>
                <td className={`content ${company?.className}`}>{isMapDrawing ?
                  <MapDrawing value={content}/> : content}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const companies = [
  {label: '要望元', column_name: 'requester'},
  {label: '要望内容要旨', column_name: 'summary_of_request'},
  {label: 'A社', column_name: 'company_a'},
  {label: 'B社', column_name: 'company_b'},
  {label: 'C社', column_name: 'company_c'},
  {label: 'D社', column_name: 'company_d'},
]

const coordinate = [
  {label: '要望地点', column_name: 'requested_location', className: 'uk-position-relative height-300'},
  {label: 'P-ID', column_name: 'p_id'},
]