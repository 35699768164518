import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { DataProps } from "@/containers/data/Data";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import { axios } from "@/lib/axios";
import { useProjectStore } from "@/stores/project";
import {
  AttributeInput,
  AttributeInputMethods,
} from "@/containers/data/Attributes/AttributeInput";
import AttributeDetail from "@/containers/data/Attributes/AttributeDetail";
import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";
import BlockModal from "@/components/BlockModal";
import ConfirmModal from "@/components/ConfirmModal";
import { useBlockerStore } from "@/stores/blocker";
import { useMessageModalStore } from "@/stores/message";
import {NaviAnswerButton} from "@/components/Attributes/NaviAnswerButton";

interface AttributesProps extends DataProps {
  data: any;
}
export default function Attributes(props: AttributesProps) {
  const childRef = useRef<AttributeInputMethods>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm();

  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const [selectedProject, selectedProjectCategory] = useProjectStore(
    (state) => [state.project, state.projectCategory]
  );
  const user = useUserStore((state) => state.user);
  const [isBlocker, setBlocker] = useBlockerStore((state) => [
    state.isBlocker,
    state.setBlocker,
  ]);
  const [tabs, setTabs] = useState<any[]>([]);
  const selectedTabIndex = Common.getTabIndex(tabs, params.tabId);
  const isLocked = Common.getLocked(props.data, user);
  const isNavi = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_NAVI;
  const canTab = tabs[selectedTabIndex]
    ? Common.canAttributeTab(
        selectedProjectCategory?.id,
        tabs[selectedTabIndex].id,
        user
      )
    : false;

  const [isCancelEditConfirm, setIsCancelEditConfirm] = useState(false);

  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;

  // 他ユーザーの更新があるので都度更新
  useEffect(() => {
    getTabs();
    setBlocker(Config.isDataEdit(props.isType)); // 煩雑
  }, [location.pathname]);

  useEffect(() => {
    if (tabs.length > 0) {
      if (
        canTab < Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_VIEW.value ||
        (props.isType === Config.Data.AttributeEdit &&
          canTab < Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_EDIT.value)
      ) {
        console.log("Attributes");
        navigate("/progress");
      }
    }
  }, [location.pathname, tabs]);

  function getTabs() {
    axios
      .get("/api/v1/tabs/list", {
        params: {
          project_category_id: selectedProjectCategory?.id,
          data_id: params.dataId,
        },
      })
      .then((result) => {
        const tabs = result.data.tabs;
        setTabs(tabs);
      })
      .catch((error) => {
        setMessage(Config.MESSAGE_NO_E39);
      });
  }

  const onClickCancel = () => {
    setBlocker(false);
    setIsCancelEditConfirm(true);
  };

  const onClickSave = async () => {
    setBlocker(false);
    if (childRef.current) {
      const result = await childRef.current.submit();
      if (result) {
        if (Config.isAttributeNew(props.isType)) {
          navigate(
            Common.attributesDetailUrl(params.projectCategoryId, result.id)
          );
        } else {
          navigate(
            Common.attributesDetailUrl(
              params.projectCategoryId,
              params.dataId,
              params.tabId
            )
          );
        }
      }
    }
  };

  const onClickEdit = () => {
    navigate(
      Common.attributesEditUrl(
        params.projectCategoryId,
        params.dataId,
        params.tabId
      )
    );
  };

  const getTabLink = (tab: any) => {
    if (Config.isAttributeNew(props.isType)) {
      return "";
    } else {
      if (tab.data_tab_statuses && tab.data_tab_statuses.length > 0 || isBasicMap) {
        return Common.attributesDetailUrl(
          params.projectCategoryId,
          params.dataId,
          tab.id
        );
      } else {
        return Common.attributesEditUrl(
          params.projectCategoryId,
          params.dataId,
          tab.id
        );
      }
    }
  };

  return (
    <div className="container">
      {tabs.length > 0 && (
        <ul className="uk-tab attribute-tab">
          {tabs.map((tab, index) => {
            if (
              Common.canAttributeTab(
                selectedProjectCategory?.id,
                tab.id,
                user
              ) === Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE.value
            )
              return null;
            const tabLink = getTabLink(tab);
            return (
              <li
                key={`tab_${tab.id}`}
                className={`${index === selectedTabIndex ? "uk-active" : ""}`}
              >
                <Link
                  to={tabLink}
                  className={tabLink === "" ? "disabled-link" : ""}
                >
                  {tab.name}
                </Link>
              </li>
            );
          })}
          <div className="right">
            {props.isType === Config.Data.AttributeDetail ? (
              <>
                {isLocked && (
                  <span className="lock-label">{`${props.data.modified_user.last_name}${props.data.modified_user.first_name}さんが編集中です`}</span>
                )}
                {isNavi && <NaviAnswerButton data_id={params.dataId}/>}
                {canTab >
                  Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_VIEW.value && (
                  <button
                    className="uk-button--m uk-button-refer uk-margin-left"
                    onClick={onClickEdit}
                    disabled={isLocked}
                  >
                    編集
                  </button>
                )}
              </>
            ) : (
              <>
                <button
                  className="uk-button--m uk-button-cancel"
                  onClick={onClickCancel}
                >
                  キャンセル
                </button>
                <button
                  className="uk-button--m uk-button-refer uk-margin-left"
                  onClick={onClickSave}
                >
                  保存
                </button>
              </>
            )}
          </div>
        </ul>
      )}
      {tabs[selectedTabIndex] && (
        <>
          {canTab > Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE.value &&
            props.isType === Config.Data.AttributeDetail && (
              <AttributeDetail
                key={`AttributeDetail_${selectedTabIndex}`}
                isType={props.isType}
                tab={tabs[selectedTabIndex]}
                tabIndex={selectedTabIndex}
              />
            )}
          {canTab > Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_VIEW.value &&
            props.isType !== Config.Data.AttributeDetail && (
              <FormProvider {...methods}>
                <AttributeInput
                  key={`AttributeInput_${selectedTabIndex}`}
                  isType={props.isType}
                  tab={tabs[selectedTabIndex]}
                  tabIndex={selectedTabIndex}
                  ref={childRef}
                />
              </FormProvider>
            )}
          <BlockModal />
          <ConfirmModal
            text="登録をキャンセルしますか？入力したデータは登録されません。"
            confirmButtonText="OK"
            isShow={isCancelEditConfirm}
            onConfirm={() => {
              setIsCancelEditConfirm(false);
              if (Config.isAttributeNew(props.isType)) {
                navigate(-1);
              } else {
                navigate(
                  Common.attributesDetailUrl(
                    params.projectCategoryId,
                    params.dataId,
                    params.tabId
                  )
                );
              }
            }}
            onCancel={() => {
              setIsCancelEditConfirm(false);
              setBlocker(Config.isDataEdit(props.isType));
            }}
          />
        </>
      )}
    </div>
  );
}
