import React, {useEffect, useState} from "react";
import {axios} from "@/lib/axios";

/**
 * カーナビ要望の要望回答公開画面表示用ボタン
 * @param data_id
 * @constructor
 */
export const NaviAnswerButton = ({data_id}: { data_id: string | undefined }) => {
  const [url, setUrl] = useState<string | null>(null);
  const openNewTab = () => {
    if (url) {
      window.open(url, "_blank")
    }
  }

  useEffect(() => {
    axios.get('/api/v1/attributes/getNaviAnswerURL', {params: {data_id}}).then(result => {
      setUrl(result.data.url)
    }).catch(error => {
    }).finally(() => {
    })
  }, [data_id])
  return (
    <button
      className="uk-button--w uk-button-refer uk-margin-left"
      onClick={openNewTab}
      disabled={!url}
    >
      公開画面の確認
    </button>
  )
}