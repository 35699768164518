import {useProgressStore} from "@/stores/progress";
import * as Config from "@/config";
import React from "react";
import {Attribute} from "@/components/ProgressList/types";

interface SharedTableBodyAttributesProps {
  data: any;
  checked: boolean;
  CollectList?: Attribute[];
}

export const SharedTableBodyAttributes = (props: SharedTableBodyAttributesProps) => {
  const [tabs, listAttributes] = useProgressStore(state => [state.tabs, state.listAttributes])
  const displayAttributes = props.CollectList ? props.CollectList : listAttributes;

  return (
    <>
      {displayAttributes.map((attr: any) => {
        const attrValues = props.data.data_attribute_values.filter((attrValue: any) => attr.id === attrValue.attribute_id);
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        const content = attrValues.map((attrValue: any) => {
          if (Config.ATTRIBUTE_CODES.includes(attrValue.attribute_kind_code)) {
            return `${attrValue.attribute_code}: ${attrValue.attribute_value}`
          } else {
            return `${attrValue.attribute_value}`
          }
        });
        return <TableBodyAttribute key={attr.id} width={width} content={content} checked={props.checked}/>
      })}
    </>
  )
}

interface TableBodyAttributeProps {
  content: any;
  checked: boolean;
  width: string;
  key?: any;
  className?: string;
}

export const TableBodyAttribute = (props: TableBodyAttributeProps) => {
  return (
    <td
      key={`shared-table-body-attribute-${props.key}`}
      className={`uk-text-left ${props.checked ? "selected" : ""} ${props.className}`}
      style={{minWidth: props.width, maxWidth: props.width}}
      data-tooltip-id="progress-tooltip" data-tooltip-html={props.content.join('<br />')}
    >
      <div className="border-box"/>
      {props.content.join('\n')}
    </td>)
}

interface SharedTableBodyTabsProps {
  data: any;
}

export const SharedTableBodyTabs = (props: SharedTableBodyTabsProps) => {
  const [tabs, listAttributes] = useProgressStore(state => [state.tabs, state.listAttributes])

  return (
    <>
      {tabs.map((tab: any) => {
        const tabStatus = tab.tab_statuses.find((tabStatus: any) => {
          return props.data.data_tab_statuses.find((dataTabStatus: any) => {
            return dataTabStatus.tab_statuses_id === tabStatus.id
          })
        })?.status ?? '';
        return (
          <td key={`shared-table-body-tabs-${tab.id}`} data-tooltip-id="progress-tooltip" data-tooltip-html={tabStatus}>
            <div className="border-box"/>
            {tabStatus}
          </td>
        )
      })}
    </>
  )
}