import {SharedTableHeadAttributes, SharedTableHeadTabNames} from "@/components/ProgressList/results/SharedTableHead";
import React, {useMemo} from "react";
import {
  SharedTableBodyAttributes,
  SharedTableBodyTabs,
  TableBodyAttribute
} from "@/components/ProgressList/results/SharedTableBody";
import {useProgressStore} from "@/stores/progress";
import * as Config from "@/config";
import {Data} from "@/config";

export const NaviTableHead = () => {
  return (
    <>
      <SharedTableHeadAttributes/>
      <SharedTableHeadTabNames/>
      <th className="uk-padding-remove-horizontal">
        <div className="border-box"/>
        <div className="uk-text-center">公開設定</div>
      </th>
    </>
  )
}

interface NaviTableBodyProps {
  data: any;
  checked: boolean;
  currentFaqStatuses: (data_id: number) => any;
}

export const NaviTableBody = (props: NaviTableBodyProps) => {
  const [tabs, listAttributes] = useProgressStore(state => [state.tabs, state.listAttributes])

  const maxStatus = useMemo(() => {
    if (!tabs?.[0]?.tab_statuses?.length) return {};
    const statuses = tabs[0].tab_statuses
      .map((item: any) => item?.code)
    return Math.max(...statuses)
  }, [tabs]);
  const finishedStatus = useMemo(() => tabs?.[0]?.tab_statuses?.find((item: any) => Number(item.code) == maxStatus), [tabs, maxStatus]);
  const isFinished = useMemo(() => props.data.data_tab_statuses.some((item: any) => item.tab_statuses_id == finishedStatus?.id), [props.data, finishedStatus]);

  const requestClassName = (isRequest: boolean, content: string) => {
    if (isRequest && !isFinished && content.length > 0) {
      const deadlineStartDatetime = props.data.data_tickets[0].deadline_start_datetime;
      if (!deadlineStartDatetime) return '';
      const deadlineDatetime = new Date(deadlineStartDatetime);
      const now = new Date();
      const twoMonthsAgo = new Date();
      const threeMonthsAgo = new Date();
      twoMonthsAgo.setMonth(now.getMonth() - 2);
      threeMonthsAgo.setMonth(now.getMonth() - 3);
      const isWithinThreeMonths = deadlineDatetime.getTime() <= threeMonthsAgo.getTime();
      const isWithinTwoMonths = deadlineDatetime.getTime() <= twoMonthsAgo.getTime();
      if (isWithinThreeMonths) {
        return 'background-red';
      } else if (isWithinTwoMonths) {
        return 'text-red';
      }
      return '';
    }
  }

  return (
    <>
      {listAttributes.map((attr: any) => {
        const isRequest = attr.column_name == Config.ATTRIBUTE_COLUMN_NAME.summaryOfRequest;
        const attrValues = props.data.data_attribute_values.filter((attrValue: any) => attr.id === attrValue.attribute_id);
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        const content = attrValues.map((attrValue: any) => {
          if (isRequest) {
            if (isFinished) return '';
            return attrValue.attribute_value.length > 0 ? '○' : ''
          } else if (Config.ATTRIBUTE_CODES.includes(attrValue.attribute_kind_code)) {
            return `${attrValue.attribute_code}: ${attrValue.attribute_value}`
          } else {
            return `${attrValue.attribute_value}`
          }
        });
        return <TableBodyAttribute key={attr.id} width={width} content={content} checked={props.checked}
                                   className={isRequest ? `uk-text-center uk-text-bold ${requestClassName(isRequest, content)}` : ''}/>
      })}
      <SharedTableBodyTabs data={props.data}/>
      {/* 公開タブのステータスリスト */}
      <td data-tooltip-id="progress-tooltip">
        <div className="border-box"/>
        {props.currentFaqStatuses(props.data.id)?.statuses?.map((item: any, index: number) => {
          return (
            <div key={`progress-faq-table-body-${index}`}>{item.status}</div>
          )
        })}
      </td>
    </>
  )
}