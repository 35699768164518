import * as Config from "@/config";
import * as Common from "@/utils/common";
import {Attribute} from "@/components/ProgressList/types";

export function useProgressListSearchResults() {

  //進捗一覧表示項目
  const listAttributes = (attributes: Attribute[], serviceStartInfoType: Config.ServiceStartInformationList, setServiceInfoSourceAttr: (serviceInfoSourceAttr: Attribute | undefined) => void, categoryId?: number) => {
    //リンク属性を除外
    let result = attributes.slice(1);
    //納品希望日を除外
    result = result.filter((attr) => attr.column_name !== Config.ATTRIBUTE_COLUMN_NAME.deliveryDate);

    if (categoryId == Config.PROJECT_CATEGORY_ID_NAVI) {
      // 要望内容要旨を除外、attributesの2つ目に「要望」を新たに追加
      result = conversionNaviAttributes(result);
    }

    if (categoryId == Config.PROJECT_CATEGORY_ID_SERVICE_START_INFO) {
      //情報源を除外
      setServiceInfoSourceAttr(result.find(attr => attr.column_name == Config.ATTRIBUTE_COLUMN_NAME.serviceInfoSource))
      result = result.filter((attr) => attr.column_name !== Config.ATTRIBUTE_COLUMN_NAME.serviceInfoSource);
      result = getServiceStartInfoAttributes(result, serviceStartInfoType);
    }

    return result
  }

  //供用開始情報_進捗一覧表示項目切り替え処理
  const getServiceStartInfoAttributes = (attrList: Attribute[], type: Config.ServiceStartInformationList) => {
    const getList = (code: string) => {
      return attrList.filter(attr => {
        return attr.attribute_setting_values.some(setting => setting.attribute_setting_code === code)
      })
    }

    const updateAttributeName = (array: Attribute[], columnName: string, newName: string) => {
      return array.map(attr => attr.column_name == columnName ? {...attr, name: newName} : attr)
    }
    const findDisplayNo = (attr: Attribute, code: string) => {
      return Number(attr.attribute_setting_values.find(setting => setting.attribute_setting_code === code)?.value)
    }
    switch (type) {
      case Config.ServiceStartInformationList.progress:
        return getList(Config.ATTRIBUTE_SETTINGS_CODE_IS_PROGRESS_LIST);
      case Config.ServiceStartInformationList.manage:
        const manageCode = Config.ATTRIBUTE_SETTINGS_CODE_MANAGE_LIST_DISPLAY_ORDER;
        let manageResult = getList(manageCode);
        manageResult = updateAttributeName(manageResult, Config.ATTRIBUTE_COLUMN_NAME.source01, "ソース（国交省：道路開通情報＞\nhttp://www.mlit.go.jp/road/kaitsu/index.html）")
        return manageResult.sort((a, b) => findDisplayNo(a, manageCode) - findDisplayNo(b, manageCode));
      case Config.ServiceStartInformationList.collect:
        const collectCode = Config.ATTRIBUTE_SETTINGS_CODE_COLLECT_LIST_DISPLAY_ORDER;
        let collectResult = getList(collectCode);
        collectResult = updateAttributeName(collectResult, Config.ATTRIBUTE_COLUMN_NAME.workDetail, "事業内容等");
        collectResult = updateAttributeName(collectResult, Config.ATTRIBUTE_COLUMN_NAME.length, "供用する区間の延長(m)");
        collectResult = updateAttributeName(collectResult, Config.ATTRIBUTE_COLUMN_NAME.commonStartYear, "供用(予定)年度");
        collectResult = updateAttributeName(collectResult, Config.ATTRIBUTE_COLUMN_NAME.commonStartMonth, "月");
        collectResult = updateAttributeName(collectResult, Config.ATTRIBUTE_COLUMN_NAME.reference, "社会資本総合整備計画番号等\n原典情報");
        return collectResult.sort((a, b) => findDisplayNo(a, collectCode) - findDisplayNo(b, collectCode));
    }
  }

  //納品希望日
  const getDeliveryDateAttr = (attributes: Attribute[]) => {
    return attributes.filter(attr => attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.deliveryDate)
  }

  const splitServiceStartInfoListAttributes = (attributes: Attribute[]) => {
    const collectListBasicInfo = attributes.filter(attr => COLLECT_LIST_BASIC_INFO.includes(attr.column_name as Config.ATTRIBUTE_COLUMN_NAME));
    const collectListServiceInfo = attributes.filter(attr => COLLECT_LIST_SERVICE_INFO.includes(attr.column_name as Config.ATTRIBUTE_COLUMN_NAME));
    const collectListDrawing = attributes.filter(attr => COLLECT_LIST_DRAWING.includes(attr.column_name as Config.ATTRIBUTE_COLUMN_NAME));
    const collectListReference = attributes.filter(attr => COLLECT_LIST_REFERENCE.includes(attr.column_name as Config.ATTRIBUTE_COLUMN_NAME));
    const collectList = attributes.filter(attr => !COLLECT_LIST_REMAINING.includes(attr.column_name as Config.ATTRIBUTE_COLUMN_NAME))
    return {collectList, collectListBasicInfo, collectListServiceInfo, collectListDrawing, collectListReference}
  }

  const serviceInfoSource = (data: any, code: string) => {

  }

  const conversionNaviAttributes = (attributes: Attribute[]) => {
    const targetIndex = attributes.findIndex(attr => attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.summaryOfRequest);
    const [targetItem] = attributes.splice(targetIndex, 1);
    const convertItem = {...targetItem, name: '要望'}
    attributes.splice(0, 0, convertItem);
    return attributes;
  }

  return {listAttributes, getDeliveryDateAttr, splitServiceStartInfoListAttributes}
}

const COLLECT_LIST_BASIC_INFO = [
  Config.ATTRIBUTE_COLUMN_NAME.roadKind,
  Config.ATTRIBUTE_COLUMN_NAME.routeNumber,
  Config.ATTRIBUTE_COLUMN_NAME.routeName,
  Config.ATTRIBUTE_COLUMN_NAME.workDetail,
  Config.ATTRIBUTE_COLUMN_NAME.workDetailOther,
  Config.ATTRIBUTE_COLUMN_NAME.sectionStartpoint,
  Config.ATTRIBUTE_COLUMN_NAME.sectionEndpoint,
];

const COLLECT_LIST_SERVICE_INFO: Config.ATTRIBUTE_COLUMN_NAME[] = [
  Config.ATTRIBUTE_COLUMN_NAME.length,
  Config.ATTRIBUTE_COLUMN_NAME.commonStartYear,
  Config.ATTRIBUTE_COLUMN_NAME.commonStartMonth
];

const COLLECT_LIST_DRAWING = [
  Config.ATTRIBUTE_COLUMN_NAME.wantOfferDrawing,
  Config.ATTRIBUTE_COLUMN_NAME.nowOfferDrawing,
  Config.ATTRIBUTE_COLUMN_NAME.locationMap,
  Config.ATTRIBUTE_COLUMN_NAME.drawingOfferToMapCompany,
  Config.ATTRIBUTE_COLUMN_NAME.remarksForRoadAdministrator
];

const COLLECT_LIST_REFERENCE = [
  Config.ATTRIBUTE_COLUMN_NAME.reference
];

const COLLECT_LIST_REMAINING = [
  ...COLLECT_LIST_BASIC_INFO,
  ...COLLECT_LIST_SERVICE_INFO,
  ...COLLECT_LIST_DRAWING,
  ...COLLECT_LIST_REFERENCE
];