import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SubHeader from "../../../components/SubHeader";
import {TextBox} from "@/components/Form/TextBox";
import {SubmitHandler, useForm} from "react-hook-form";
import {TextArea} from "@/components/Form/TextArea";
import {useLoadingStore} from "@/stores/loading";
import {createGroup, getGroupsInfo, updateGroup} from "@/api/groups";
import {GroupsCheckBox} from "@/components/Form/GroupsCheckBox";
import ConfirmModal from "@/components/ConfirmModal";
import {
  MESSAGE_NO_E08,
  MESSAGE_NO_E35,
  MESSAGE_NO_E36,
  MESSAGE_NO_E37,
  MESSAGE_NO_E39,
  PERMISSION_ROLL_SYSADMIN,
} from "@/config";
import {useUserStore} from "@/stores/user";
import {SelectBox} from "@/components/Form/SelectBox";
import * as Common from "@/utils/common";

interface Block {
  id: number;
  code: string;
  name: string;
  checked: boolean;
}

interface Area {
  id: number;
  code: string;
  name: string;
  checked: boolean;
  blocks: Block[];
}

interface Project {
  id: number;
  name: string;
  areas: Area[];
}

export interface GroupInfo {
  id?: number;
  name?: string;
  zipcode?: string;
  address?: string;
  representative_phone_number?: string;
  representative_email_address?: string;
  charge_person_name?: string;
  charge_person_phone_number?: string;
  remarks?: string;
  projects: Project[];
  last_updated_by?: string;
  last_updated_at?: string;
  nav_company_tag?: string;
}

export default function GroupEdit() {
  const navigate = useNavigate();
  const groupId = useParams().id;
  const setLoading = useLoadingStore((state) => state.setLoading);
  const user = useUserStore((state) => state.user);
  const title = groupId ? "編集" : "新規作成";

  const [groupInfo, setGroupInfo] = useState<GroupInfo>();
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    setError,
    formState: {errors},
  } = useForm<GroupInfo>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    criteriaMode: "all",
    defaultValues: {},
    values: groupInfo,
  });
  const hasErrors = Object.keys(errors).length > 0;

  const breadCrumbs = [
    {title: "管理画面", path: "/admin"},
    {title: "グループ一覧", path: "/admin/groups"},
    {title: title},
  ];

  const onClickCancel = () => {
    setIsShowBackModal(true);
  };

  const onClickSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit: SubmitHandler<GroupInfo> = (data) => {
    setLoading(true);
    const response = groupId ? updateGroup(data) : createGroup(data);
    response
      .then((result) => {
        navigate(-1);
      })
      .catch((error) => {
        const errors = error.response.data.response.errors;
        if (errors.nav_company_tag) {
          setError("nav_company_tag", {
            type: "sever",
            message: errors.nav_company_tag,
          })
        } else {
          setErrorMessage(MESSAGE_NO_E37);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?.roll !== PERMISSION_ROLL_SYSADMIN) {
      navigate("/admin");
    }
    setLoading(true);
    const response = groupId ? getGroupsInfo(groupId) : getGroupsInfo();
    response
      .then((result) => {
        setGroupInfo(result.data);
      })
      .catch((error) => {
        setErrorMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage]);

  const watchedProjects = watch("projects", []);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="グループ管理" showPrompt/>
        {groupId && (
          <div className="uk-text-right uk-margin-small-bottom">
            最終更新ユーザ：{groupInfo?.last_updated_by} 最終更新:
            {groupInfo?.last_updated_at}
          </div>
        )}
        {hasErrors && <div className="error-box">入力エラーがあります</div>}
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div className="precaution">※は必須です</div>
          <div>
            <button
              className="uk-button--m uk-button-cancel"
              onClick={onClickCancel}
            >
              キャンセル
            </button>
            <button
              className="uk-button--m uk-button-refer uk-margin-left"
              onClick={onClickSave}
            >
              保存
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tr>
                <td>
                  グループ名<span className="error">※</span>
                </td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: {value: 30, message: MESSAGE_NO_E35(30)},
                      }}
                      errors={errors}
                      placeholder={"グループ名を入力してください"}
                    />
                  </div>
                  {errors.name && (
                    <p className="precaution">{errors.name.message}</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>郵便番号</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="zipcode"
                      register={register}
                      rules={{
                        maxLength: {value: 10, message: MESSAGE_NO_E35(10)},
                      }}
                      errors={errors}
                      placeholder={"郵便番号を入力してください"}
                    />
                  </div>
                  {errors.zipcode && (
                    <p className="precaution">{errors.zipcode.message}</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>住所</td>
                <td>
                  <div className="uk-width-1-1 uk-flex">
                    <TextBox
                      name="address"
                      register={register}
                      rules={{
                        maxLength: {value: 200, message: MESSAGE_NO_E35(200)},
                      }}
                      errors={errors}
                      placeholder={"住所を入力してください"}
                    />
                  </div>
                  {errors.address && (
                    <p className="precaution">{errors.address.message}</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>代表電話番号</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="representative_phone_number"
                      register={register}
                      rules={{
                        maxLength: {value: 21, message: MESSAGE_NO_E35(21)},
                      }}
                      errors={errors}
                      placeholder={"代表電話番号を入力してください"}
                    />
                  </div>
                  {errors.representative_phone_number && (
                    <p className="precaution">
                      {errors.representative_phone_number.message}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td>代表アドレス</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="representative_email_address"
                      register={register}
                      rules={{
                        maxLength: {value: 254, message: MESSAGE_NO_E35(254)},
                      }}
                      errors={errors}
                      placeholder={"代表アドレスを入力してください"}
                    />
                  </div>
                  {errors.representative_email_address && (
                    <p className="precaution">
                      {errors.representative_email_address.message}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td>責任者名</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="charge_person_name"
                      register={register}
                      rules={{
                        maxLength: {value: 60, message: MESSAGE_NO_E35(60)},
                      }}
                      errors={errors}
                      placeholder={"責任者名を入力してください"}
                    />
                  </div>
                  {errors.charge_person_name && (
                    <p className="precaution">
                      {errors.charge_person_name.message}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td>責任者電話番号</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="charge_person_phone_number"
                      register={register}
                      rules={{
                        maxLength: {value: 21, message: MESSAGE_NO_E35(21)},
                      }}
                      errors={errors}
                      placeholder={"責任者電話番号を入力してください"}
                    />
                  </div>
                  {errors.charge_person_phone_number && (
                    <p className="precaution">
                      {errors.charge_person_phone_number.message}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td>備考</td>
                <td>
                  <TextArea
                    name="remarks"
                    register={register}
                    rules={{
                      maxLength: {value: 200, message: MESSAGE_NO_E36},
                    }}
                    errors={errors}
                    placeholder={"備考を入力してください"}
                  />
                  {errors.remarks && (
                    <p className="precaution">{errors.remarks.message}</p>
                  )}
                </td>
              </tr>
            </table>
          </div>

          <div className="admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
              <tr>
                <td>カーナビ会社等表示名</td>
                <td>
                  <div className="width-40p uk-flex">
                    <SelectBox
                      name="nav_company_tag"
                      register={register}
                      options={NAV_COMPANY_TAG}
                    />
                  </div>
                  {errors.nav_company_tag && (
                    <p className="precaution">{errors.nav_company_tag.message}</p>
                  )}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          {watchedProjects.map((project, projectIndex) => (
            <div
              className="admin-tbl uk-margin-top uk-margin-bottom"
              key={projectIndex}
            >
              <table className="uk-width-1-1">
                <tr>
                  <td>プロジェクト名</td>
                  <td>{project.name}</td>
                </tr>
                <tr>
                  <td>エリア</td>
                  <td>
                    <div>
                      <GroupsCheckBox
                        name={`projects.${projectIndex}.areas`}
                        options={project.areas}
                        register={register}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    ブロック
                    {project.areas.some((a) => a.checked) && (
                      <span className="error">※</span>
                    )}
                  </td>
                  <td>
                    <div>
                      {project.areas.map(
                        (area, areaIndex) =>
                          area.checked && (
                            <>
                              <GroupsCheckBox
                                name={`projects.${projectIndex}.areas.${areaIndex}.blocks`}
                                areaName={area.code + "：" + area.name}
                                options={area.blocks}
                                register={register}
                                rules={{
                                  validate: () =>
                                    area.blocks.some((b) => b.checked) ||
                                    MESSAGE_NO_E08,
                                }}
                                setValue={setValue}
                                errors={errors}
                              />
                              {errors.projects?.[projectIndex]?.areas?.[
                                areaIndex
                                ]?.blocks && (
                                <p className="precaution">{MESSAGE_NO_E08}</p>
                              )}
                            </>
                          )
                      )}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          ))}
        </form>
      </div>
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          navigate(-1);
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={isShowErrorModal}
          onConfirm={() => {
            setIsShowErrorModal(false);
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
}

const NAV_COMPANY_TAG = [
  {label: 'A社', value: 'A社'},
  {label: 'B社', value: 'B社'},
  {label: 'C社', value: 'C社'},
  {label: 'D社', value: 'D社'},
];