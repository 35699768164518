import * as Config from "@/config";

interface Other {
  otherId?: number,
  otherValue?: string;
  parentId?: number,
}

export const isOtherItem = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_OTHER_PARENT_ATTRIBUTE_ID)
}

export const addOtherParentProperty = (attributes: any, data: any) => {
  const otherParentAttributes = attributes.reduce((acc: any[], attribute: any) => {
    let other: Other = {otherId: attribute.id};
    let hasParentId = false;

    attribute.attribute_setting_values.forEach((setting: any) => {
      if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_OTHER_PARENT_ATTRIBUTE_ID) {
        other = {...other, parentId: setting.value};
        hasParentId = true;
      }
    });

    if (hasParentId) {
      acc.push(other);
    }

    return acc;
  }, []);

  return {
    ...data,
    data_attribute_values: addOtherParentPropertyToDAV(data.data_attribute_values, otherParentAttributes)
  };
}

const otherDataAttributeValue = (DAV: any, otherId: number | undefined) => {
  return DAV.find((dav: any) => dav.attribute_id == otherId).attribute_value
}

const addOtherParentPropertyToDAV = (DAV: any, otherParentAttributes: Other[]) => {
  return DAV.map((dav: any) => {
    let resultDav = {...dav};

    otherParentAttributes.forEach((other) => {
      const isTarget = dav.attribute_id == other.parentId && dav.attribute_value == 'その他';
      if (isTarget) {
        const otherContent = otherDataAttributeValue(DAV, other.otherId);
        resultDav = {...resultDav, otherContent};
      }
    });

    return resultDav;
  });
}

export const filterAttributes = (attributes: any) => {
  let result = attributes;
  result = excludeRelatedOther(result);
  return result;
}

const excludeRelatedOther = (attributes: any) => {
  return attributes.filter((attribute: any) => {
    return !attribute.attribute_setting_values.some((s: any) => {
      return s.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_OTHER_PARENT_ATTRIBUTE_ID
    })
  })
}
export const getOtherItemParentAttributeId = (attribute: any) => {
  return attribute.attribute_setting_values.find((setting: any) => {
    return setting.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_OTHER_PARENT_ATTRIBUTE_ID
  })?.value
}
export const showOtherItem = (parentValue: string) => {
  if (!parentValue) {
    return false;
  }
  const [option_id, code, value] = parentValue.split(Config.SPLIT_CODE);
  return value === 'その他';
}