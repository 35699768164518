import {ProjectCategory} from "@/stores/project";
import {useUserStore} from "@/stores/user";
import {useMessageModalStore} from "@/stores/message";
import {useSearchBasicMap} from "@/components/Attributes/BasicMap/useSearchBasicMap";
import {basicMapStore} from "@/stores/basicMap";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useFixedColumns} from "@/utils/useFixedColumns";
import * as Config from "@/config";
import {CATEGORY_CODE_FAQ, CATEGORY_CODE_SALES_APPLY} from "@/config";
import {useInterval} from "@/utils/UseInterval";
import {axios} from "@/lib/axios";
import * as Common from "@/utils/common";
import {Tooltip} from "react-tooltip";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import {SearchResults} from "@/containers/progress/ProgressList";
import {useProgressListSearchResults} from "@/components/ProgressList/useProgressListSearchResults";
import {
  SharedTableHeadAttributes,
  SharedTableHeadTabNames,
} from "@/components/ProgressList/results/SharedTableHead";
import {SalesApplyTableBody, SalesApplyTableHead} from "@/components/ProgressList/results/SalesApply";
import {BasicMapTableBody, BasicMapTableHead} from "@/components/ProgressList/results/BasicMap";
import {FaqTableBody, FaqTableHead} from "@/components/ProgressList/results/Faq";
import {useProgressStore} from "@/stores/progress";
import {
  ServiceStartInfoTableBody,
  ServiceStartInfoTableHead,
  ServiceStartInfoTableHeadAttributes
} from "@/components/ProgressList/results/ServiceStartInfo";
import {SharedTableBodyAttributes, SharedTableBodyTabs} from "@/components/ProgressList/results/SharedTableBody";
import {useLoadingStore} from "@/stores/loading";
import {SortIndicator} from "@/components/SortIndicator";
import {ApprovalTableBody, ApprovalTableHead} from '@/components/ProgressList/results/Approval';
import {NaviTableBody} from "@/components/ProgressList/results/Navi";

/**
 * 検索結果
 */
export interface SearchResultsContainerProps {
  areaName: string,
  blockName: string,
  searchResults: SearchResults,
  checkIds: Set<number>,
  onClickCheck: (id: number) => void,
  onClickCheckAll: () => void,
  onPageChange: (page: number) => void,
  selectedTabStatusesId: number | undefined,
  onSelectedTabStatusesId: (tabStatusesId: number) => void,
  selectedTabId: number | undefined,
  onSelectedTabId: (tabId: number) => void,
  selectedProjectCategory: ProjectCategory | undefined,
  onSelectedFaq: () => void,
  onSelectedFaqStatus: (statusId: number) => void,
  officialPositions: any[]
}

export const SearchResultsContainer = (props: SearchResultsContainerProps) => {
  const user = useUserStore(state => state.user);
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [setTabs, setListAttributes, serviceStartInfoType, setServiceInfoSourceAttr] = useProgressStore(state => [state.setTabs, state.setListAttributes, state.serviceStartInfoType, state.setServiceInfoSourceAttr])
  const {getSearchResultBasicMapStatus} = useSearchBasicMap();
  const {listAttributes, getDeliveryDateAttr} = useProgressListSearchResults();
  const {certificationStatus, submissionStatus} = basicMapStore();
  const progressTableRef = useRef<HTMLTableElement>(null);

  const isBasicMap = props.selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isSalesApply = props.selectedProjectCategory?.category_code === CATEGORY_CODE_SALES_APPLY;
  const isFaq = props.selectedProjectCategory?.category_code === CATEGORY_CODE_FAQ;
  const isServiceStartInfo = props.selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_SERVICE_START_INFO;
  const isApproval = props.selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_APPROVAL;
  const isNavi = props.selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_NAVI;

  const isNotServiceStartInfoProgress = serviceStartInfoType !== Config.ServiceStartInformationList.progress;
  const isServiceStartInfoCollect = serviceStartInfoType === Config.ServiceStartInformationList.collect;

  const linkAttr = props.searchResults.attributes[0];
  const linkListWidth = linkAttr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
  const linkWidth = linkListWidth ? `${linkListWidth.value}px` : '200px';

  const listAttrs = useMemo(() =>
      listAttributes(props.searchResults.attributes, serviceStartInfoType, setServiceInfoSourceAttr, props.selectedProjectCategory?.id),
    [props.searchResults.attributes, props.selectedProjectCategory?.id, serviceStartInfoType]
  );
  const desiredDeliveryDateAttr = getDeliveryDateAttr(props.searchResults.attributes);

  const approverMembers = props.searchResults.approverMembers;

  const getTabStatusCount = (tab_statuses_id: number) => {
    const count = props.searchResults.dataTabStatusCounts.find((count: any) => count.tab_statuses_id === tab_statuses_id);
    return count?.tab_statuses_count ?? 0;
  }

  const allTabCounts = props.searchResults.dataTabStatusCounts.reduce((accumulator, value) => {
    if (accumulator[value.tab_id]) {
      accumulator[value.tab_id] += value.tab_statuses_count;
    } else {
      accumulator[value.tab_id] = value.tab_statuses_count;
    }
    return accumulator;
  }, {});

  const allFaqCounts = props.searchResults.data_faq_status_counts?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.faq_statuses_count, 0
  )

  const currentFaqStatuses = (data_id: number) => {
    return props.searchResults.faq_statuses?.find((item) => {
      return item.id === data_id
    })
  }

  const [lockedList, setLockedList] = useState<any[]>([]);

  useEffect(() => {
    getLockedList();
  }, []);

  useEffect(() => {
    setListAttributes(listAttrs);
  }, [listAttrs]);

  useEffect(() => {
    setTabs(props.searchResults.tabs);
  }, [props.searchResults.tabs]);

  useInterval(() => {
    getLockedList();
  }, 60 * 1000);

  const getLockedList = () => {
    const dataIds = props.searchResults.datas.map((data: any) => data.id);
    if (dataIds.length == 0) {
      return;
    }
    axios.get('/api/v1/datas/lockedList', {
      params: {
        data_id: dataIds,
      }
    }).then(result => {
      setLockedList(result.data.data);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
    });
  }

  const getLink = (project_category_id: any, data_id: any) => {
    if (Common.canApprove(project_category_id, user) && (isSalesApply || isApproval)) {
      return Common.approveDetailUrl(project_category_id, data_id);
    }
    const canAttribute = Common.canShowAttribute(project_category_id, user);
    if (canAttribute === true) {
      return Common.attributesDetailUrl(project_category_id, data_id);
    } else if (canAttribute) {
      return Common.attributesDetailUrl(project_category_id, data_id, canAttribute.tab_id);
    }
    if (Common.canFile(project_category_id, user) > 0)
      return Common.filesListUrl(project_category_id, data_id);
    return '';
  }

  const renderTableHeadAttributes = () => {
    if (isSalesApply) {
      return (
        <SalesApplyTableHead
          desiredDeliveryDateAttr={desiredDeliveryDateAttr}
          approverMembers={approverMembers}
          officialPositions={props.officialPositions}
        />
      );
    }
    if (isBasicMap) {
      return <BasicMapTableHead/>;
    }
    if (isFaq) {
      return <FaqTableHead/>;
    }
    if (isServiceStartInfo) {
      return <ServiceStartInfoTableHead/>
    }
    if (isApproval) {
      return (
        <ApprovalTableHead
          desiredDeliveryDateAttr={desiredDeliveryDateAttr}
          approverMembers={approverMembers}
          officialPositions={props.officialPositions}
        />
      );
    }
    return (
      <>
        <SharedTableHeadAttributes/>
        <SharedTableHeadTabNames/>
      </>
    );
  };

  useFixedColumns(progressTableRef);

  return (
    <>
      <div className="progress-tbl-wrap mt-5">
        <Tooltip id="progress-tooltip" style={{zIndex: 3}}/>
        <table className={`progress-tbl ${isBasicMap && "fixed-columns"}`} ref={progressTableRef}>
          <thead>
          <tr className="header">
            {/* チェックボックス */}
            <th rowSpan={2}>
              <div className="border-box"/>
              <div className="uk-flex uk-flex-center">
                <input
                  type="checkbox"
                  onChange={props.onClickCheckAll}
                  checked={props.searchResults.datas.length > 0 && props.checkIds.size === props.searchResults.datas.length}/>
              </div>
            </th>
            {/* リンク属性 */}
            <th rowSpan={2} style={{minWidth: linkWidth, maxWidth: linkWidth}} className="sort-indicator-wrap">
              <div className="border-box"/>
              {linkAttr.name}
              <SortIndicator item_name={Config.ProgressListSortItem.ATTRIBUTES} column_name={linkAttr.column_name}/>
            </th>
            {renderTableHeadAttributes()}
          </tr>
          <tr className="header">
            {/* 共通化タブステータス */}
            {!isApproval && !isBasicMap && !isNotServiceStartInfoProgress &&
                <>
                  {props.searchResults.tabs.map((tab: any) => {
                    return (
                      <th key={`table-head-tab-${tab.id}`} className="uk-padding-remove-horizontal">
                        <div className="border-box"/>
                        <div className="status-box">
                          {tab.tab_statuses.map((tabStatus: any) => {
                            return (
                              <div className="" key={`progress-list-tab-status-${tab.id}-${tabStatus.id}`}>{
                                props.selectedTabStatusesId !== tabStatus.id ?
                                  <a
                                    onClick={() => props.onSelectedTabStatusesId(tabStatus.id)}>{tabStatus.status}：{getTabStatusCount(tabStatus.id)}</a>
                                  :
                                  <span>{tabStatus.status}：{getTabStatusCount(tabStatus.id)}</span>
                              }</div>)
                          })}
                          <div className="">{
                            props.selectedTabId !== tab.id ?
                              <a onClick={() => props.onSelectedTabId(tab.id)}>全件：{allTabCounts[tab.id]}</a>
                              :
                              <span>全件：{allTabCounts[tab.id]}</span>
                          }</div>
                        </div>
                      </th>
                    )
                  })}
                </>
            }
            {/*  公開タブステータスのテーブルヘッダー　*/}
            {isFaq &&
                <th className="uk-padding-remove-horizontal">
                    <div className="border-box"/>
                    <div className="status-box">
                      {props.searchResults.data_faq_status_counts?.map((faqStatus: any) => {
                        return (
                          <div key={`search-result-header-faq-${faqStatus.id}`} className="">
                            <a
                              onClick={() => props.onSelectedFaqStatus(faqStatus.id)}>{faqStatus.name}：{faqStatus.faq_statuses_count}</a>
                          </div>
                        )
                      })}
                        <div className="">
                            <a onClick={() => props.onSelectedFaq()}>全件：{allFaqCounts}</a>
                        </div>
                    </div>
                </th>
            }
            {isServiceStartInfoCollect && <ServiceStartInfoTableHeadAttributes/>}
          </tr>
          </thead>
          <tbody>
          {props.searchResults.datas.map(((data: any) => {
            const checked = props.checkIds.has(data.id);
            const linkAttrValue = data.data_attribute_values.find((attrValue: any) => linkAttr.id === attrValue.attribute_id);
            const link = getLink(linkAttrValue?.project_category_id, linkAttrValue?.data_id);
            const isLocked = lockedList.find(locked => locked.id === data.id) !== undefined;
            const basicMapStatus = getSearchResultBasicMapStatus(props.searchResults.basic_map_statuses, data.id, certificationStatus, submissionStatus);

            const renderTableBody = () => {
              if (isSalesApply) {
                return (
                  <SalesApplyTableBody data={data} checked={checked} approverMembers={approverMembers}
                                       desiredDeliveryDateAttr={desiredDeliveryDateAttr}
                                       officialPositions={props.officialPositions}
                  />
                );
              }
              if (isBasicMap) {
                return <BasicMapTableBody data={data} checked={checked} basicMapStatus={basicMapStatus}/>;
              }
              if (isNavi) {
                return <NaviTableBody data={data} checked={checked} currentFaqStatuses={currentFaqStatuses}/>
              }
              if (isFaq) {
                return <FaqTableBody data={data} checked={checked} currentFaqStatuses={currentFaqStatuses}/>;
              }
              if (isServiceStartInfo) {
                return <ServiceStartInfoTableBody data={data} checked={checked}/>
              }
              if (isApproval) {
                return (
                  <ApprovalTableBody data={data} checked={checked} approverMembers={approverMembers}
                                     desiredDeliveryDateAttr={desiredDeliveryDateAttr}
                                     officialPositions={props.officialPositions}
                  />
                );
              }
              return (
                <>
                  <SharedTableBodyAttributes data={data} checked={checked}/>
                  <SharedTableBodyTabs data={data}/>
                </>
              );
            };

            return (
              <tr key={`search-results-record-${data.id}`} className={checked ? "selected" : ""}>
                {/* チェックボックス */}
                <td className={checked ? "selected" : ""}>
                  <div className="border-box"/>
                  <div className="uk-flex uk-flex-center"><input type="checkbox" onChange={() => {
                    props.onClickCheck(data.id)
                  }} checked={checked}/></div>
                </td>
                {/* リンク属性 */}
                <td
                  className={"uk-text-left" + (checked ? " selected" : "")}
                  style={{minWidth: linkWidth, maxWidth: linkWidth}}
                  data-tooltip-id="progress-tooltip"
                  data-tooltip-html={linkAttrValue?.attribute_value}
                >
                  <div className="border-box"/>
                  {link ? <Link to={link}>{linkAttrValue?.attribute_value}</Link> :
                    <span>{linkAttrValue?.attribute_value}</span>}
                  {isLocked && <div><span
                      className="lock-label">{`${data?.modified_user.last_name}${data?.modified_user.first_name}さんが編集中です`}</span>
                  </div>}
                </td>
                {renderTableBody()}
              </tr>
            )
          }))}
          </tbody>
        </table>
      </div>
      <div
        className="uk-text-center mt-5">全{props.searchResults.paging.count}件（{props.searchResults.paging.page}/{props.searchResults.paging.pageCount}）
      </div>
      <div className="pagination-container">
        <a onClick={() => {
          props.onPageChange(1)
        }}>FIRST</a>
        <ReactPaginate
          forcePage={props.searchResults.paging.page - 1} // ReactPaginate→0始まり CakePHP→1始まり
          onPageChange={(selectedItem: { selected: number }) => props.onPageChange(selectedItem.selected + 1)}
          pageRangeDisplayed={5}
          pageCount={props.searchResults.paging.pageCount}
          renderOnZeroPageCount={null}
          containerClassName='pagination'
          previousLabel='<'
          nextLabel='>'
          breakLabel='...'
        />
        <a onClick={() => {
          props.onPageChange(props.searchResults.paging.pageCount)
        }}>LAST</a>
      </div>
    </>
  )
}