import ConfirmModal from "@/components/ConfirmModal";
import useAutosizeTextArea from "@/utils/useAutosizeTextArea";
import React, {Fragment, useEffect, useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import clipLIcon from "@/assets/images/clip_l.png";
import {useFieldArray, useForm} from "react-hook-form";
import * as Config from "@/config";
import {useLoadingStore} from "@/stores/loading";
import {useUserStore} from "@/stores/user";
import {postComment} from "@/api/ticket";
import * as Common from "@/utils/common";
import {useProjectStore} from "@/stores/project";
import {useBlockerStore} from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";
import {Ticket, TicketDestinations} from "@/components/Ticket/types";
import {customerInfo} from "@/containers/customer/Ticket/types";

interface Props {
  ticket?: Ticket;
  ticketDestList: TicketDestinations[];
  getTicket: () => void;
  customer?: customerInfo;
}

export interface PostComment {
  data_id?: number;
  customer_id?: number;
  data_ticket_id: number;
  is_inquiry_user: boolean;
  created_by: number;
  comment: string;
  ticket_destinations_id: number;
  attached_files?: {
    original_filename: string;
    file_content: string;
  }[];
  is_deadline: boolean;
}

interface ModalMessage {
  submit?: boolean;
  message?: string;
}

export default function MessageInputBox({
                                          ticket,
                                          ticketDestList,
                                          getTicket,
                                          customer
                                        }: Props) {
  const setLoading = useLoadingStore((state) => state.setLoading);
  const user = useUserStore((state) => state.user);
  const [isBlocker, setBlocker] = useBlockerStore((state) => [
    state.isBlocker,
    state.setBlocker,
  ]);
  const [selectedProject, selectedProjectCategory] = useProjectStore(
    (state) => [state.project, state.projectCategory]
  );
  const canTicket =
    Common.canTicket(selectedProjectCategory?.id, user) >
    Config.RESTRICTION_HISTORY_TICKET_VIEW.value;

  const {
    register,
    trigger,
    watch,
    control,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<PostComment>({
    reValidateMode: "onSubmit",
    defaultValues: {
      ticket_destinations_id: -1,
      comment: '',
      is_deadline: false
    }
  });
  const {fields, append, remove} = useFieldArray({
    name: "attached_files",
    control,
  });
  const watchTextArea = watch("comment");
  const watchFiles = watch("attached_files");
  const ticketDestinationId = watch("ticket_destinations_id");
  const isSysAdmin = Config.PERMISSION_ROLL_SYSADMIN == user?.roll;
  const isNavi = selectedProjectCategory?.id == Config.PROJECT_CATEGORY_ID_NAVI;
  const toDXAdminerName = () => {
    const template = (text: string) => `一般財団法人日本デジタル道路地図協会 ${text}ご担当者様`
    if (customer) {
      return template('課金システム');
    }
    switch (selectedProject?.id) {
      case Config.PROJECT_ID_SALE_APPLY:
        return template('申込管理');
      case Config.PROJECT_ID_DRM_PF:
        return template('DRM-PF FAQ');
      case Config.PROJECT_ID_NAVI:
        return template('カーナビ要望');
    }
  }
  const toInquirer = customer ? customer.group_name + " " + customer.last_name + customer.first_name : `${ticket?.inquiry_company_name ?? ''} ${ticket?.inquiry_user_name ?? ''}`;

  useAutosizeTextArea("ticket-comment-textarea", watchTextArea);
  const [modalMessage, setModalMessage] = useState<ModalMessage>({});

  const onFileChange = (files: FileList) => {
    remove();
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target?.result;
        append({original_filename: file.name, file_content: String(base64)});
      };
      reader.readAsDataURL(file);
    });
  };

  const onClickSend = () => {
    if (canTicket) {
      const setMessage = () => {
        if (ticketDestinationId == -1) return {message: "送信先を選択してください。"}
        if (watchTextArea.length == 0) return {message: Config.MESSAGE_NO_E66}
        return {submit: true, message: Config.MESSAGE_NO_E41}
      }
      setModalMessage(setMessage);
    }
  };

  const onConfirm = () => {
    if (canTicket) {
      modalMessage.submit && onSubmit();
      setModalMessage({});
    }
  };

  const onCancel = () => {
    setModalMessage({});
  };

  const onSubmit = async () => {
    clearErrors();
    const variableId = ticket?.data_id ? {data_id: Number(ticket?.data_id)} : {customer_id: Number(customer?.id)};
    const data = {
      ...getValues(),
      ...variableId,
      data_ticket_id: Number(ticket?.data_ticket_id),
      is_inquiry_user: false,
      created_by: Number(user?.id),
    };
    try {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      setLoading(true);
      await postComment(data);
      getTicket();
      reset({});
      remove();
    } catch (error: any) {
      const serverErrors = error.response?.data?.errors;

      if (serverErrors?.is_deadline) {
        setError("is_deadline", {
          type: "server",
          message: serverErrors.is_deadline,
        });
        setValue('is_deadline', false)
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const bool =
      !!(watchFiles && watchFiles.length > 0) ||
      !!(watchTextArea && watchTextArea.length > 0);
    setBlocker(bool);
  }, [watchFiles, watchTextArea]);

  return (
    <>
      <div className="message-sendto-box">
        <div>送信先</div>
        <select
          value={ticketDestinationId}
          disabled={ticket?.is_close}
          onChange={(e) => {
            setValue("ticket_destinations_id", Number(e.target.value));
          }}
        >
          <option value={-1}>未選択</option>
          {isSysAdmin ?
            <option value={0}>{toInquirer}</option> :
            <option value={-2}>{toDXAdminerName()}</option>}
          {ticketDestList.map((ticketDest) => (
            <option key={ticketDest.id} value={ticketDest.id}>
              {ticketDest.name}
            </option>
          ))}
        </select>
      </div>
      <div className="message-input-box">
        <textarea
          id="ticket-comment-textarea"
          {...register("comment", {
            required: Config.MESSAGE_NO_E08,
            maxLength: {value: 1000, message: Config.MESSAGE_NO_E35(1000)},
          })}
          rows={3}
          disabled={ticket?.is_close}
        />
        {errors.comment && (
          <p className="precaution">{errors.comment.message}</p>
        )}
        <hr/>
        <div className="uk-flex uk-flex-middle">
          <FileUploader
            classes="file-drop"
            name={"files"}
            handleChange={(files: any) => onFileChange(files)}
            multiple={true}
            children={
              <>
                {fields.length > 0 ? (
                  <div className="uk-flex">
                    {fields.map((file, index) => (
                      <Fragment key={index}>
                        <img
                          src={clipLIcon}
                          width={22}
                          height={22}
                          className="uk-margin-small-right"
                        />
                        {file.original_filename}
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <div className="uk-flex">
                    <img
                      src={clipLIcon}
                      width={22}
                      height={22}
                      className="uk-margin-small-right"
                    />
                    添付ファイルがある場合にはクリックして選択するか、ファイルをドロップしてください
                  </div>
                )}
              </>
            }
          />
          {isNavi && isSysAdmin &&
              <div>
                  <label className="w-150">
                      <input
                          type="checkbox"
                          {...register(`is_deadline`)}
                      />回答期限を設定する
                  </label>
                {errors.is_deadline && (
                  <p className="precaution">{errors.is_deadline.message}</p>
                )}
              </div>
          }
          <button
            className="uk-button--m uk-button-refer"
            onClick={onClickSend}
            disabled={ticket?.is_close}
          >
            送信
          </button>
        </div>
        <BlockModal/>
        <ConfirmModal
          text={modalMessage.message ?? ""}
          confirmButtonText="OK"
          isShow={!!modalMessage.message}
          onConfirm={onConfirm}
          onCancel={modalMessage.submit ? onCancel : undefined}
        />
      </div>
    </>
  );
}
